/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'add': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 0c.269 0 .492.194.539.45l.009.098v6.904h6.905a.548.548 0 01.098 1.087l-.098.009H8.547v6.905a.548.548 0 01-1.087.098l-.009-.098V8.547H.548A.548.548 0 01.449 7.46l.099-.009h6.904V.548C7.452.245 7.698 0 8 0z" _fill="#1D3861"/>'
  }
})
