import Vue from 'vue';

import SendBirdAction from '@/scripts/sendbird-integration/SendBirdAction';
import SendBirdSyncManagerAction from '@/scripts/sendbird-integration/SendBirdSyncManagerAction';
import sbUtils from '@/scripts/sendbird-integration/utils';
import { isNullOrEmpty } from '@/scripts/utils';
import { MESSAGES_TYPES, SENDBIRD, URLS } from '@/configs/constants';

import activeChannel from './ActiveChannelStore';

const sbAction = new SendBirdAction();
const sbSyncAction = new SendBirdSyncManagerAction();

const legacyGetPatientFromMembers = (members, currentUserId) => {
  // this is an outdated way of getting the patient from the member list.
  // New members come with a "role" metadata, which should be used for this
  // type of filter.
  const member = members.find(m => m.userId !== currentUserId);
  return member || {};
};

const getPatient = (members, currentUserId) => {
  const patient = sbUtils.getFirstPatientFromMembersList(members);

  if (!patient || !Object.keys(patient).length) {
    return legacyGetPatientFromMembers(members, currentUserId);
  }

  return patient;
};

const memberToMemberItemFormat = (channel, member, memberSendbirdDetails) => ({ channel, patient: member, ...memberSendbirdDetails });

export default {
  namespaced: true,
  modules: {
    activeChannel,
  },
  state: {
    channels: [],
    members: [],
    fetchedMemberUuids: [],
    nickname: null,
    plainProfileUrl: null,
    userId: null,
    connectionStatus: null,
    lastSeenAt: null,
    metaData: {},
    isActive: false,
    friendDiscoveryKey: null,
    friendName: null,
    _preferredLanguages: [],
    requireAuth: false,
    counters: { all: 0, unread: 0, pending: 0 },
    selectedPage: 1,
  },
  getters: {
    getSendbirdData: state => state,
    getAllFetchedUuids: state => state.fetchedMemberUuids,
    getChannels: state => state.channels,
    getMembers: state => state.members,
    getSelectedPage: state => state.selectedPage,
    getSendbirdId: state => state.userId,
    getCounters: state => state.counters,
    getMemberDetailsByChannel: (state, getters, _, rootGetters) => channel => {
      const currentUserId = getters.getSendbirdId;
      const sendbirdDetails = getPatient(channel.members, currentUserId) ?? null;
      const patientSbId = sendbirdDetails?.userId ?? null;

      if (!patientSbId) {
        return null;
      }

      const patientSwordId = sbUtils.sendbirdIdToSwordId(patientSbId);

      return {
        sendbirdId: patientSbId,
        sendbirdDetails,
        swordDetails: rootGetters['patient/getPatients']?.find(p => patientSwordId === p?.id) ?? null,
      };

    },
  },
  mutations: {
    pushFetchedMemberUuids(state, uuids = []) {
      const memberUuids = state.fetchedMemberUuids.concat(uuids);
      Vue.set(state, 'fetchedMemberUuids', memberUuids);
    },
    setSelectedPage(state, page) {
      Vue.set(state, 'selectedPage', page);
    },
    setChannels(state, channels) {
      Vue.set(state, 'channels', channels);
    },
    setMembers(state, members) {
      Vue.set(state, 'members', members);
    },
    setSendbirdUser(state, payload) {
      Object.keys(payload).forEach(key => {
        if (key in state) {
          Vue.set(state, key, payload[key]);
        }
      });
    },
    setCounters(state, { counter, counterType = MESSAGES_TYPES.ALL }) {
      state.counters[counterType] = counter;
    },
    updateChannel(state, newChannelBody) {
      const channelIndex = state.channels.findIndex(c => c.url === newChannelBody.url);
      state.channels.splice(channelIndex, 1, newChannelBody);
    },
    addOrUpdateMember(state, { channel, memberSwordDetails, memberSendbirdDetails, force = true }) {
      if (!memberSwordDetails) {
        return;
      }
      const memberIndex = state.members.findIndex(m => m.userId === memberSendbirdDetails.userId);
      const memberItemBody = memberToMemberItemFormat(channel, memberSwordDetails, memberSendbirdDetails);

      if (memberIndex !== -1) {
        state.members.splice(memberIndex, 1, memberItemBody);
      } else if (force) {
        const channelIndex = state.channels.findIndex(c => c.url === channel.url);
        if (channelIndex !== -1) {
          state.members.splice(channelIndex, 0, memberItemBody);
        }
      }
    },
  },
  actions: {
    getCurrentUser() {
      return sbAction.getCurrentUser();
    },
    async connectToSendbird({ rootGetters, getters, commit }) {
      const userId = getters.getSendbirdId;
      const accessToken = rootGetters['user/auth/sendbirdToken'];

      try {
        console.log('[sendbird] initializing sync manager');
        await sbSyncAction.setup(userId);
        // sbSyncAction.manager.clearCache();

        console.log(`[sendbird] connecting to sendbird with userId ${userId}`);
        const user = await sbAction.connect(userId, accessToken);
        commit('setSendbirdUser', user);
        return user;
      } catch (error) {
        console.log('[sendbird] Error connecting to sendbird', error);
        throw error;
      }
    },
    async fetchChannelList(
      { getters, commit, dispatch },
      { isInit, merge, channelUrls, nameFilter, messagesType } = {
        isInit: false,
        merge: false,
        channelUrls: [],
        nameFilter: null,
        messagesType: 'all',
      },
    ) {
      let channelsToFetch = channelUrls;
      if (messagesType === MESSAGES_TYPES.PENDING) {
        const selectedPage = getters.getSelectedPage;
        channelsToFetch = await dispatch('fetchPendingMessages', { nameFilter, selectedPage });
      }
      const newChannels = await sbAction.getGroupChannelList(isInit, channelsToFetch, nameFilter, messagesType);
      let updatedChannels = [];
      let membersToUpdate = [];
      if (merge) {
        const channels = getters.getChannels;
        updatedChannels = [ ...channels, ...newChannels ];
        membersToUpdate = newChannels;
      } else {
        updatedChannels = newChannels;
        membersToUpdate = null;
      }

      commit('setChannels', updatedChannels);
      await dispatch('updateMemberList', membersToUpdate);
      if (newChannels.length) {
        dispatch('fetchPatientsForChannels');
      }

      /* TODO: this is a temporary workaround to show the number of search matches, since it seems sendbird does
      not allow to get total count with filters. A better strategy should be discussed to implement this feature properly;
      If search results are higher than the result number per request (CHANNEL_QUERY_CHUNK_SIZE), this value is not correct */
      if (nameFilter) {
        commit('setCounters', { counter: newChannels.length, counterType: messagesType });
      }
    },
    async fetchPatientsForChannels({ getters, dispatch, commit }) {
      const fetchedUuids = getters.getAllFetchedUuids || [];
      const uuids = [
        ...new Set(getters.getMembers.filter(member => {
            const memberUuid = member.metaData.member_account_uuid;
            return !!memberUuid && fetchedUuids.indexOf(memberUuid) === -1;
          }).map(member => member.metaData.member_account_uuid) || []),
      ];

      if (uuids.length) {
        await commit('pushFetchedMemberUuids', uuids);
        await dispatch('patient/fetchPatients', uuids, { root: true });
        dispatch('updateMemberList');
      }
    },
    updateMemberList({ getters, rootGetters, commit, dispatch }, newChannels) {
      const currentUserId = getters.getSendbirdId;
      const channels = newChannels || getters.getChannels;
      const patients = rootGetters['patient/getPatients'];

      let updatedMembers = channels.reduce((acc, channel) => {
        const member = getPatient(channel.members, currentUserId);
        if (isNullOrEmpty(member)) {
          return acc;
        }

        if (isNullOrEmpty(patients)) {
          acc.push({ ...member, channel, patient: null });
          return acc;
        }
        const patient = patients.find(p => p?.member_account_id === member?.metaData?.member_account_uuid);
        acc.push({ ...member, channel, patient });
        return acc;
      }, []);

      if (newChannels) {
        const members = getters.getMembers;
        updatedMembers = [ ...members, ...updatedMembers ];
      }

      commit('setMembers', updatedMembers);
      dispatch('activeChannel/setActiveChannelMember');
    },
    async refreshChannelList({ getters, commit, dispatch }) {
      const currentUserId = getters.getSendbirdId;
      const channels = getters.getChannels;
      const members = getters.getMembers;

      let refreshedMembers;
      try {
        refreshedMembers = await channels.reduce(async (accPromise, channel) => {
          const accMembers = await accPromise;
          const refreshedChannel = await channel.refresh();
          const refreshedMember = getPatient(refreshedChannel.members, currentUserId);

          return accMembers.map(member => {
            if (member.userId === refreshedMember.userId) {
              return { ...member, ...refreshedMember };
            }
            return member;
          });
        }, Promise.resolve(members));
      } catch (error) {
        refreshedMembers = members.slice();
      }

      commit('setMembers', refreshedMembers);
      dispatch('activeChannel/setActiveChannelMember');
    },
    async getMemberAndChannelDataByChannel({ getters, dispatch }, channel) {
      const memberDetails = getters.getMemberDetailsByChannel(channel);

      // No member found in channel. Do nothing
      if (!memberDetails?.sendbirdId) {
        return null;
      }

      const userSwordId = sbUtils.sendbirdIdToSwordId(memberDetails.sendbirdDetails.userId);
      let memberData = memberDetails?.swordDetails;

      // Patient still not fetched, so do it and add it to members list
      if (userSwordId && !memberData) {
        try {
          [ memberData ] = await dispatch('patient/fetchPatients', [ userSwordId ], { root: true });
        } catch (e) {
          memberData = null;
          console.error(`[fetch-patient] Unable to fetch patient ${userSwordId}`, e);
        }
      }

      return {
        channel,
        memberSwordDetails: memberData,
        memberSendbirdDetails: memberDetails.sendbirdDetails,
      };
    },
    async moveChannelAndMemberToTop({ getters, commit, dispatch }, channel) {

      const memberAndChannelData = await dispatch('getMemberAndChannelDataByChannel', channel);
      const { memberSwordDetails, memberSendbirdDetails } = memberAndChannelData;

      const channels = getters.getChannels;
      const members = getters.getMembers;
      const remainingChannels = channels.filter(c => c.url !== channel.url);
      const remainingMembers = members.filter(m => m.userId !== memberSendbirdDetails.userId);
      const memberItemBody = memberToMemberItemFormat(channel, memberSwordDetails, memberSendbirdDetails);
      commit('setChannels', [ channel, ...remainingChannels ]);
      commit('setMembers', [ memberItemBody, ...remainingMembers ]);
    },
    async addOrUpdateChannel({ commit, dispatch }, channel) {
      const memberAndChannelData = await dispatch('getMemberAndChannelDataByChannel', channel);

      if (!memberAndChannelData) {
        return;
      }

      const { memberSwordDetails, memberSendbirdDetails } = memberAndChannelData;

      // Update channel
      commit('updateChannel', channel);
      // Update member
      commit('addOrUpdateMember', {
        channel,
        memberSwordDetails,
        memberSendbirdDetails,
      });
    },
    setMemberStatus({ getters, commit, dispatch }, { channel, isOnline }) {
      const currentUserId = getters.getSendbirdId;
      const members = getters.getMembers;

      const refreshedMember = getPatient(channel?.members, currentUserId);
      refreshedMember.connectionStatus = isOnline ? 'online' : 'offline';

      const refreshedMembers = members.map(member => {
        if (refreshedMember && member.userId === refreshedMember.userId) {
          return { ...member, ...refreshedMember };
        }
        return member;
      });

      commit('setMembers', refreshedMembers);
      dispatch('activeChannel/setActiveChannelMember');
    },
    setMemberDraft: ({ getters, commit }, { member, textMessage }) => {
      const members = getters.getMembers;

      const updatedMembers = members.map(currentMember => {
        if (currentMember.userId === member.userId) {
          return { ...currentMember, draft: textMessage };
        }
        return currentMember;
      });
      commit('setMembers', updatedMembers);
    },
    async fetchAllMessagesCount({ commit }) {
      const messages = await sbAction.getTotalChannelCount();
      commit('setCounters', { counter: messages || 0, counterType: MESSAGES_TYPES.all });
    },
    async fetchUnreadMessagesCount({ commit }) {
      const unreadMessages = await sbAction.getTotalUnreadChannelCount();
      commit('setCounters', { counter: unreadMessages || 0, counterType: MESSAGES_TYPES.UNREAD });
    },
    async fetchPendingMessagesCount({ rootGetters, commit }) {
      try {
        const { accessToken } = rootGetters['user/auth/getAuth'];
        const { data: pendingMessages } = await Vue.$http.get(
          `${URLS.THERAPIST_API}v1/chat/totals/with-pending-messages`,
          { headers: { Authorization: accessToken } },
        );
        commit('setCounters', { counter: pendingMessages.with_pending_messages || 0, counterType: MESSAGES_TYPES.PENDING });
      } catch (error) {
        console.error(`[pending-messages] It was not possible to fetch pending messages count: ${error}`);
      }
    },
    async fetchPendingMessages({ rootGetters }, { nameFilter, selectedPage }) {
      try {
        const { accessToken } = rootGetters['user/auth/getAuth'];
        const { data: pendingMessages } = await Vue.$http.get(
          `${URLS.THERAPIST_API}v1/chat/patients/with-pending-messages`,
          {
            headers: { Authorization: accessToken },
            params: {
              limit: SENDBIRD.CHANNELS_CHUNK_SIZE,
              offset: (selectedPage - 1) * SENDBIRD.CHANNELS_CHUNK_SIZE,
              members_nickname_contains: nameFilter,
            },
          },
        );
        return pendingMessages.sendbird.channels;
      } catch (error) {
        console.error(`[pending-messages] It was not possible to fetch pending messages list: ${error}`);
        return [];
      }
    },
    resetTabCounter({ dispatch }, selectedFilter) {
      switch (selectedFilter) {
        case MESSAGES_TYPES.PENDING:
          dispatch('fetchPendingMessagesCount');
          break;
        case MESSAGES_TYPES.UNREAD:
          dispatch('fetchUnreadMessagesCount');
          break;
        default:
          dispatch('fetchAllMessagesCount');
      }
    },
  },
};
