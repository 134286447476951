/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'back-arrow': {
    width: 16,
    height: 16,
    viewBox: '0 0 34 34',
    data: '<path pid="0" d="M3.9 17.9h28.6c.5 0 1-.4 1-1s-.4-1-1-1H4l8.6-8.8c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L.9 16.2c-.4.4-.4.9-.1 1.3 0 .1.1.2.2.2l10.3 10.5c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4l-8.8-8.9z"/>'
  }
})
