let defaults = require('./defaults.json');
const { isNullOrEmpty } = require('../scripts/utils');


if (process.env.NODE_ENV === 'development') {
  try {
    // eslint-disable-next-line
    defaults = require('./local.json');
  } catch {
    console.info('Using default.json vars');
  }
}

const keyExistsInObject = (object, key) => {
  if (isNullOrEmpty(object) || isNullOrEmpty(key)) {
    return false;
  }

  if (Object.prototype.hasOwnProperty.call(object, key)) {
    return !isNullOrEmpty(object[key]);
  }

  return false;
};

export default {
  get: key => {
    if (keyExistsInObject(window.CONFIG, key)) {
      try {
        return JSON.parse(window.CONFIG[key]);
      } catch {
        return window.CONFIG[key];
      }
    }

    console.debug(`Unable to find ${key} in window config. Retrieving default value`);
    return defaults[key];
  },
};
