import Vue from 'vue';
import AuthenticationError from '@/scripts/AuthenticationError';

import firebase from 'firebase/app';
import 'firebase/messaging';

import { URLS } from '@/configs/constants';
import configs from '@/configs';
import auth from './AuthStore';
import cookies from './CookieStore';


const defaultUserData = () => ({
  sessionId: window.btoa(new Date().getTime()),
  details: {
    id: null,
    firstName: '',
    lastName: '',
    picture: '',
    gender: 'unknown',
    hasProviderEnabled: false,
  },
  deviceToken: null,
  chatUser: {},
});

export default {
  namespaced: true,
  modules: {
    auth,
    cookies,
  },
  state: {
    ...defaultUserData(),
  },
  getters: {
    getLoggingSessionId: state => state.sessionId,
    getUserId: state => state.details.id,
    getUserDetails: state => state.details,
    getChatUser: state => state.chatUser,
    getDeviceToken: state => state.deviceToken,
  },
  mutations: {
    setCurrentUserDetails(state, payload) {
      Object.keys(payload).forEach(key => {
        if (key in state.details) {
          Vue.set(state.details, key, payload[key]);
        }
      });
    },
    resetCurrentUserData(state) {
      const rawState = defaultUserData();
      Object.entries(rawState)
        .forEach(([ prop, value ]) => {
          Vue.set(state, prop, value);
        });
    },
    setDeviceToken(state, token) {
      state.deviceToken = token;
    },
  },
  actions: {
    async fetchUserData({ getters }) {
      const accessToken = getters['auth/accessToken'];
      try {
        const { data } = await Vue.$http.get(`${URLS.THERAPIST_API}v1/me`, { headers: { Authorization: accessToken } });
        return data;
      } catch (e) {
        console.log('Error getting user credentials', e);
        throw e;
      }
    },
    async handleCookieAuth({ dispatch, commit }, redirect = true) {
      // Initiating app basics
      console.warn('[auth] handling user authentication with cookies');
      try {
        await dispatch('setUserFromCookies');
        const userData = await dispatch('fetchUserData');
        const {
          sendbird: sendbirdData,
          firstname: firstName,
          lastname: lastName,
          picture,
          gender,
          id,
          has_provider_enabled: hasProviderEnabled,
        } = userData;
        const { sendbird_id: userId, nickname, access_token: accessToken } = sendbirdData;

        commit('setCurrentUserDetails', {
          firstName, lastName, picture, gender, id, hasProviderEnabled: !!hasProviderEnabled,
        });
        commit('chat/setSendbirdUser', { userId, nickname }, { root: true });
        commit('auth/setSendbirdToken', accessToken);

      } catch (e) {
        if (e instanceof AuthenticationError) {
          console.log(e);
          if (redirect) {
            dispatch('auth/routeUserOnAuthError');
          }
          return;
        }
        console.error('[auth] Error Getting user Auth.');
        throw e;
      }
    },
    async handleTokenAuth({ dispatch }, token) {
      console.warn('[auth] handling user authentication with cookies');
      await dispatch('call/group/exchangeTokenForRoomInfo', token, { root: true });
    },
    async setUserFromCookies({ dispatch }) {
      const sharedCookiesData = await dispatch('cookies/getUpdatedUserCookies');

      if (!sharedCookiesData) {
        // Cookies have not been update, so we don't need to do anything else.
        return;
      }
      const { tokens } = sharedCookiesData;
      await dispatch('setupNewSession', { tokens });
    },
    setupNewSession({ commit }, { tokens }) {
      console.log('[bug-track] Booting a new session');

      // Discard old stuff
      commit('resetCurrentUserData');

      // Set fresh user
      const { accessToken, refreshToken } = tokens;
      commit('auth/setAuthTokens', { accessToken, refreshToken });
    },
    async registerPushNotifications({ commit, dispatch }) {
      if (!firebase.messaging.isSupported()) {
        console.warn('[notifications] cannot register notifications due to FCM being unsupported');
        return null;
      }

      const deviceToken = this.getters['user/getDeviceToken'];
      if (deviceToken) {
        await dispatch('updateDeviceToken', deviceToken);
        console.log(`[notifications] Registered device with saved token ${deviceToken}`);
        return deviceToken;
      }

      try {
        await dispatch('requestNotificationPermission');
        const token = await dispatch('fetchDeviceToken');
        commit('setDeviceToken', token);
        await dispatch('updateDeviceToken', token);
        console.log(`[notifications] Registered device with token ${token}`);
        return token;
      } catch (error) {
        console.log('[notifications] Error getting device token', error);
        return null;
      }
    },
    async requestNotificationPermission() {
      const permission = await Notification.requestPermission();
      switch (permission) {
        case 'granted':
          console.log('[notifications] Page notifications permission is granted.');
          break;
        case 'denied':
          console.warn('[notifications] Page notifications permission denied. Device token registration will fail.');
          throw permission;
        default:
          console.warn('[notifications] Page notifications permission are on default settings. Device token registration may fail.');
          throw permission;
      }
    },
    async fetchDeviceToken() {
      const fcm = firebase.messaging();
      try {
        return await fcm.getToken({ vapidKey: configs.get('FIREBASE_VAPIDKEY') });
      } catch (error) {
        console.warn('[notifications] An error occurred while retrieving token. ', error);
        throw error;
      }
    },
    async updateDeviceToken({ getters, rootGetters }, token) {
      const accessToken = getters['auth/accessToken'];
      const { appVersion } = rootGetters;

      await Vue.$http.post(
        `${URLS.THERAPIST_API}v1/user/device-token`,
        { identifier: token, manufacturer: 'web', app_version: appVersion },
        { headers: { Authorization: accessToken } },
      );
    },
  },
};
