/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'envelope-pending': {
    width: 16,
    height: 16,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M0 1.825C0 .817.873 0 1.949 0h15.304C18.328 0 19.2.817 19.2 1.825v6.4a6.483 6.483 0 00-1.2-.206V1.77c0-.315-.274-.57-.614-.571H1.815c-.34 0-.615.256-.615.571v12.057c0 .316.275.572.615.572h9.186a6.657 6.657 0 00.092 1.2H1.948a2.015 2.015 0 01-1.378-.534 1.768 1.768 0 01-.57-1.29V1.824z" _fill="#1F222C"/><path pid="1" d="M12.104 7.693l1.6 1.53c-.345.248-.664.53-.953.839l-1.586-1.518-1.056.953a.62.62 0 01-.837.005l-1.027-.924-4.08 3.847a.625.625 0 01-.887-.034.644.644 0 01.03-.902l3.99-3.766-4.005-3.608a.642.642 0 01-.051-.902.622.622 0 01.886-.052l4.47 4.032a.855.855 0 01.093.081l.003.007.992.893 5.538-5.01a.625.625 0 01.886.049.645.645 0 01-.047.902l-3.959 3.578z" _fill="#1F222C"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M14 12.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-.502c-.01.758-.066 1.625-.36 2.347-.187.46-.479.884-.932 1.19-.452.305-1.023.463-1.72.463-.698 0-1.268-.158-1.719-.465-.45-.306-.738-.732-.92-1.191-.288-.722-.337-1.588-.345-2.344H14.5a.5.5 0 01-.5-.5zm2.003.5c.009.729.054 1.425.272 1.974.128.32.306.566.555.735.248.169.61.291 1.155.291s.91-.122 1.162-.292c.252-.17.434-.417.565-.738.223-.548.274-1.244.285-1.97h-3.994z" _fill="#1F222C"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M22 21.5a.5.5 0 01-.5.5h-7a.5.5 0 010-1h.502c.01-.758.066-1.625.36-2.347.188-.46.48-.884.932-1.19.452-.305 1.023-.463 1.72-.463.698 0 1.269.158 1.719.465.45.306.738.732.921 1.191.287.722.336 1.588.345 2.344h.501a.5.5 0 01.5.5zm-2.002-.5h-3.995c.011-.726.062-1.422.285-1.97.131-.321.313-.568.565-.738.252-.17.617-.292 1.162-.292.545 0 .907.122 1.155.291.249.17.427.414.555.735.218.55.264 1.245.273 1.974z" _fill="#1F222C"/>'
  }
})
