/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'retry': {
    width: 16,
    height: 16,
    viewBox: '0 0 15 17',
    data: '<path pid="0" d="M8.4 2.1l.3-.3c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-2 2c-.4.4-.4 1 0 1.4l2 2c.2.1.4.2.7.2s.5-.1.7-.3c.4-.4.4-1 0-1.4l-.2-.2c2.6.5 4.5 2.7 4.5 5.4 0 3-2.5 5.5-5.5 5.5S2 12.5 2 9.5c0-.6-.4-1-1-1s-1 .4-1 1C0 13.6 3.4 17 7.5 17S15 13.6 15 9.5c0-3.8-2.9-7-6.6-7.4z" _fill="#fff"/>'
  }
})
