/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'archive': {
    width: 28,
    height: 32,
    viewBox: '0 0 28 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M28 29.176V8.471c0-.007-.002-.013-.004-.02-.001-.005-.003-.011-.003-.017a.939.939 0 00-.066-.31.297.297 0 00-.013-.039c-.003-.005-.004-.01-.006-.016-.002-.007-.003-.013-.007-.02l-3.217-6.488A2.783 2.783 0 0022.18 0H5.82a2.783 2.783 0 00-2.504 1.56L.098 8.05c-.003.006-.004.012-.006.019a.125.125 0 01-.006.016c-.005.012-.01.025-.013.038l-.008.024a.94.94 0 00-.058.287.07.07 0 01-.003.017c-.002.007-.004.013-.004.02v20.705c.002 1.56 1.254 2.822 2.8 2.824h22.4c1.546-.002 2.798-1.265 2.8-2.824zM21.963 1.882h.006a.927.927 0 01.829.52L25.34 7.53H15.004V1.882h6.96zM2.588 7.53h10.395V1.882H5.964a.927.927 0 00-.834.52L2.588 7.53zM2 9.765h24v19.09a.917.917 0 01-.923.91H2.923a.917.917 0 01-.923-.91V9.766z" _fill="#1D3861"/>'
  }
})
