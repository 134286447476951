/* eslint-disable class-methods-use-this */
import SendBird from 'sendbird';
import { SENDBIRD, MESSAGES_TYPES } from '@/configs/constants';
import { isNull } from '@/scripts/utils';

const { APP_ID, CHANNELS_CHUNK_SIZE, CHANNELS_ORDER_BY } = SENDBIRD;
let instance = null;

const UNREAD_CHANNEL_FILTER = {
  [MESSAGES_TYPES.ALL]: 'all',
  [MESSAGES_TYPES.UNREAD]: 'unread_message',
};

class SendBirdAction {
  constructor() {
    if (instance) {
      return instance;
    }
    this.sb = new SendBird({
      appId: APP_ID,
    });
    this.userQuery = null;
    this.openChannelQuery = null;
    this.groupChannelQuery = null;
    this.previousMessageQuery = null;
    this.participantQuery = null;
    this.blockedQuery = null;
    instance = this;
  }

  /**
   * Connect
   */
  connect(userId, accessToken) {
    return new Promise((resolve, reject) => {
      const sb = SendBird.getInstance();
      sb.connect(userId, accessToken, (user, error) => {
        if (error) {
          reject(error);
        } else {
          resolve(user);
        }
      });
    });
  }

  disconnect() {
    return new Promise((resolve, reject) => {
      this.sb.disconnect((response, error) => (
        error ? reject(error) : resolve(response)
      ));
    });
  }

  /**
   * User
   */
  getCurrentUser() {
    return this.sb.currentUser;
  }

  isCurrentUser(user) {
    return user.userId === this.sb.currentUser.userId;
  }

  /**
   * Channel
   */
  getChannel(channelUrl, isOpenChannel = false) {
    return new Promise((resolve, reject) => {
      if (isOpenChannel) {
        this.sb.OpenChannel.getChannel(channelUrl, (openChannel, error) => (
          error ? reject(error) : resolve(openChannel)
        ));
      } else {
        this.sb.GroupChannel.getChannel(channelUrl, (groupChannel, error) => (
          error ? reject(error) : resolve(groupChannel)
        ));
      }
    });
  }

  /**
   * Group Channel
   */
  getGroupChannelList(isInit = false, channelUrls = [], nameFilter, messagesType = MESSAGES_TYPES.ALL) {
    if (messagesType === MESSAGES_TYPES.PENDING) {
      if (!channelUrls.length) {
        return Promise.resolve([]);
      }
      this.groupChannelQuery.hasNext = true;
    }

    if (isInit || isNull(this.groupChannelQuery)) {
      this.groupChannelQuery = this.sb.GroupChannel.createMyGroupChannelListQuery();
      this.groupChannelQuery.limit = CHANNELS_CHUNK_SIZE;
      this.groupChannelQuery.includeEmpty = true;
      this.groupChannelQuery.order = CHANNELS_ORDER_BY;
      if (nameFilter) {
        console.log('searching with', nameFilter);
        this.groupChannelQuery.nicknameContainsFilter = nameFilter;
      }

      if (messagesType !== MESSAGES_TYPES.PENDING) {
        this.groupChannelQuery.unreadChannelFilter = UNREAD_CHANNEL_FILTER[messagesType];
      }
    }

    if (channelUrls) {
      this.groupChannelQuery.channelUrlsFilter = channelUrls;
    }

    return new Promise((resolve, reject) => {
      if (this.groupChannelQuery.hasNext && !this.groupChannelQuery.isLoading) {
        this.groupChannelQuery.next((list, error) => (
          error ? reject(error) : resolve(list)
        ));
      } else {
        resolve([]);
      }
    });
  }

  markAsRead(channel) {
    channel.markAsRead();
  }

  /**
   * Message
   */
  getMessageList(channel, isInit = false) {
    if (isInit || isNull(this.previousMessageQuery)) {
      this.previousMessageQuery = channel.createPreviousMessageListQuery();
    }
    return new Promise((resolve, reject) => {
      if (this.previousMessageQuery.hasMore && !this.previousMessageQuery.isLoading) {
        this.previousMessageQuery.load(50, false, (messageList, error) => (
          error ? reject(error) : resolve(messageList)
        ));
      } else {
        resolve([]);
      }
    });
  }

  getReadReceipt(channel, message) {
    if (this.isCurrentUser(message.sender)) {
      return channel.getReadReceipt(message);
    }
    return 0;
  }

  sendUserMessage(channel, message, handler) {
    return channel.sendUserMessage(message, (newMessage, error) => {
      if (handler) handler(newMessage, error);
    });
  }

  sendFileMessages(channel, files, handler) {
    const fileMessageParams = files.map(file => {
      const params = new this.sb.FileMessageParams();
      params.file = file;
      if (file.type?.split('/')[0] === 'video') {
        params.thumbnailSizes = [ { maxWidth: 320, maxHeight: 320 } ];
      }
      return params;
    });
    return channel.sendFileMessages(fileMessageParams, { sent: (newMessage, error) => {
      if (handler) handler(newMessage, error);
    } });
  }

  resendUserMessage(channel, message, handler) {
    return channel.resendUserMessage(message, (newMessage, error) => {
      if (handler) handler(newMessage, error);
    });
  }

  getTotalChannelCount() {
    return new Promise((resolve, reject) => {
      this.sb.getGroupChannelCount((response, error) => (
        error ? reject(error) : resolve(response)
      ));
    });
  }

  getTotalUnreadChannelCount() {
    return new Promise((resolve, reject) => {
      this.sb.getTotalUnreadChannelCount((response, error) => (
        error ? reject(error) : resolve(response)
      ));
    });
  }

  static getInstance() {
    return new SendBirdAction();
  }
}

export default SendBirdAction;
