/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-check': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.515 6.515c4.686-4.687 12.283-4.687 16.97 0 4.687 4.686 4.687 12.283 0 16.97-4.686 4.687-12.283 4.687-16.97 0-4.687-4.686-4.687-12.283 0-16.97zm1.06 1.06c-4.1 4.101-4.1 10.749 0 14.85 4.101 4.1 10.748 4.1 14.85 0 4.1-4.101 4.1-10.749 0-14.85-4.102-4.1-10.749-4.1-14.85 0zm10.58 4.442a.745.745 0 01.563.495c.079.249.01.519-.181.706l-4.47 4.552a.78.78 0 01-.557.23.78.78 0 01-.557-.23l-1.49-1.517a.692.692 0 01-.18-.707.745.745 0 01.562-.495.787.787 0 01.731.223l.934.95 3.914-3.985a.786.786 0 01.73-.222z" _fill="#00DCA4"/>'
  }
})
