/**
 * Setting up translations plugin.
 * Language imports and bootstrap config are done here
 * @module Language Configs
 */

// Vue App dependencies
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { languagesConfigs } from '@/scripts/i18n-config/language-configs';

Vue.use(VueI18n);
const i18n = new VueI18n(languagesConfigs.i18nOptions);

export default i18n;
