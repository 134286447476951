import axios from 'axios';
import { newrelic } from '@sword-health/ui-core';

import store from '@/store';
import { isAuthErrorType } from '@/configs/constants';

axios.interceptors.response.use(
  r => r,
  async e => {
    if (!e || !e.response || !e.response.status || !e.response.status) {
      return Promise.reject(e);
    }

    newrelic.noticeApiError(e.response.status, e.response);

    const resp = e.response;
    const originalRequest = e.config;
    const userIsLoggedIn = store.getters['user/auth/isLoggedIn'];

    if (
      userIsLoggedIn
      && isAuthErrorType('ACCESS_TOKEN_EXPIRED', resp)
      && !originalRequest.url.includes('auth/refresh') // TODO fix this
    ) {
      console.log('[auth] User token is expired... Will refresh');
      try {
        const tResp = await store.dispatch('user/auth/refreshAuthToken');
        const newToken = tResp.data.access_token;
        // Set new token
        store.commit('user/auth/assignNewAccessToken', newToken);
        // Update original request
        originalRequest.headers.Authorization = newToken;
        // Run Previous actions
        return axios.request(originalRequest);
      } catch (error) {
        console.error('[auth] Unable to refresh token.');
        store.dispatch('user/auth/routeUserOnAuthError');
        throw error;
      }
    }

    if (isAuthErrorType('*', resp)) {
      console.error('[auth] User token is invalid.');
      store.dispatch('user/auth/routeUserOnAuthError');
    }

    return Promise.reject(e);
  },
);
