/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'microphone': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15 8.293c.276 0 .5.236.5.527 0 3.143-2.193 5.732-5 6.009l-.001 2.116h2.358c.276 0 .5.236.5.528a.52.52 0 01-.419.52l-.08.007H7.142a.514.514 0 01-.5-.527.52.52 0 01.419-.52l.08-.008H9.5v-2.116C6.692 14.55 4.5 11.963 4.5 8.82c0-.291.224-.527.5-.527s.5.236.5.527c0 2.755 2.02 4.979 4.5 4.979s4.5-2.224 4.5-4.979c0-.291.224-.527.5-.527zM10 2c1.46 0 2.643 1.248 2.643 2.787V8.92c0 1.54-1.183 2.787-2.643 2.787-1.46 0-2.643-1.248-2.643-2.787V4.787C7.357 3.247 8.54 2 10 2zm0 1.055c-.907 0-1.643.775-1.643 1.732V8.92c0 .957.736 1.733 1.643 1.733s1.643-.776 1.643-1.733V4.787c0-.957-.736-1.732-1.643-1.732z" _fill="#1D3861"/>'
  }
})
