/**
 * Setting up translations configs.
 * Language imports and bootstrap config are done here
 * @module Language Configs
 */

import configs from '@/configs';
import { language as languagesTools } from '@sword-health/ui-core';

const preferredLanguage = 'en';
const languagesFolderContext = require.context('@/languages', true, /\.json$/);

const languagesConfigs = languagesTools.swordVueLanguages(languagesFolderContext, preferredLanguage, configs.get('LANGUAGE_FALLBACK'));

export { languagesConfigs, preferredLanguage };

