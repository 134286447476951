import Cookies from 'js-cookie';
import AuthenticationError from '@/scripts/AuthenticationError';
import { AUTH_COOKIE, AUTH_ERRORS } from '@/configs/constants';

const auth = {
  namespaced: true,
  state: {
    existingCookies: false,
  },
  getters: {
    haveAuthCookies: state => state.existingCookies,
    hasAuthCookies() {
      // Check if these cookies are set.
      const accessTokenCookie = Cookies.get(AUTH_COOKIE.token_name);
      const refreshTokenCookieData = Cookies.get(AUTH_COOKIE.refresh_token_name);
      return accessTokenCookie && refreshTokenCookieData;
    },
  },
  mutations: {
    setCookiesExistance(state, exist) {
      state.existingCookies = exist;
    },
  },
  actions: {
    async getUpdatedUserCookies({ dispatch, rootGetters }) {
      console.log('[bug-track] Setting new user from cookies');
      // Extracting auth & user data shared though Cookies
      const sharedCookiesData = await dispatch('safeCheckUserCookies');

      /*
        There were not issues extracting user
        Assigning extracted data
      */
      const { refreshToken: cookieRefreshToken } = sharedCookiesData.tokens;

      /*
        User can have changed on background
        Ensure we're still the same
        If not, set the new one
      */
      // TODO AT THIS POINT WE NOW USER ID
      //  SO WE CAN ALSO USE THAT TO CHECK.
      //  BUT ITs COOL TO HAVE A NEW REFRESH TOKEN
      const { refreshToken: currentRefreshToken } = rootGetters['user/auth/getAuth'];
      const newSessionOnPortal = cookieRefreshToken && cookieRefreshToken !== currentRefreshToken;

      if (!newSessionOnPortal) {
        console.log('[bug-track] User on same session. Keep auth.');
        return null;
      }

      return sharedCookiesData;
    },
    async safeCheckUserCookies({ commit, dispatch }) {
      commit('setCookiesExistance', false);
      const cookiesData = await dispatch('extractAndValidateCookiesData');
      commit('setCookiesExistance', true);
      return cookiesData;
    },
    extractAndValidateCookiesData({ rootGetters }) {
      let accessTokenCookieData;
      let refreshTokenCookieData;

      try {
        // Trying to get data from all required cookies
        accessTokenCookieData = Cookies.get(AUTH_COOKIE.token_name);
        refreshTokenCookieData = Cookies.get(AUTH_COOKIE.refresh_token_name);
      } catch (error) {

        const noCookiesToAuthenticate = error instanceof DOMException;

        if (!noCookiesToAuthenticate) {
          throw error;
        }

        let errorMessage;
        let errorCode;

        // Missing cookies defintions
        if (noCookiesToAuthenticate) {

          // Check existence of in-memory tokens
          const { accessToken, refreshToken } = rootGetters['user/auth/getAuth'];
          const userHadToken = accessToken && refreshToken;

          // Has tokens in memory but cookies disappeared, so user logged out on portal
          if (userHadToken) {
            console.warn('[auth] User ended session on Portal');
            errorMessage = 'User logged out on Portal';
            errorCode = AUTH_ERRORS.LOGGED_OUT_ON_PORTAL;
          } else {
            // User never has a session on portal
            console.warn('[auth] Missing cookies to authenticate');
            // eslint-disable-next-line max-len
            errorMessage = `Missing tokens in cookies. Are there cross-domain cookies called ${AUTH_COOKIE.token_name} and ${AUTH_COOKIE.refresh_token_name}?`;
            errorCode = AUTH_ERRORS.MISSING_TOKEN;
          }
        }

        throw new AuthenticationError({
          message: errorMessage,
          errorCode,
        });

      }

      // There aren't error getting data from cookie, but validate it
      const anyTokenMissing = !accessTokenCookieData || !refreshTokenCookieData;
      // TODO NEEDED TO VALIDATE EXTRACTED DATA ABOUT USER
      if (!anyTokenMissing) {
        return {
          tokens: {
            accessToken: accessTokenCookieData,
            refreshToken: refreshTokenCookieData,
          },
        };
      }

      let errorMessage;
      let errorCode = AUTH_ERRORS.MISSING_TOKEN;

      // Checking if all ok with shared auth data
      if (anyTokenMissing) {
        console.warn('[auth] Missing cookies to authenticate');
        // eslint-disable-next-line max-len
        errorMessage = `Missing tokens in cookies. Are there cross-domain cookies called ${AUTH_COOKIE.token_name} and ${AUTH_COOKIE.refresh_token_name}?`;
        errorCode = AUTH_ERRORS.MISSING_TOKEN;
      }

      throw new AuthenticationError({
        message: errorMessage,
        errorCode,
      });

    },
    deleteAuthCookies({ commit }) {
      const cookiesToRemove = [
        AUTH_COOKIE.token_name,
        AUTH_COOKIE.refresh_token_name,
        AUTH_COOKIE.user_cookie_name,
      ];

      cookiesToRemove.forEach(cookie => Cookies.remove(cookie, { domain: AUTH_COOKIE.token_domain }));
      commit('setCookiesExistance', false);
    },
  },
};

export default auth;
