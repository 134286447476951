/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'prescriptions': {
    width: 26,
    height: 32,
    viewBox: '0 0 26 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20 2V.963C20 .43 19.545 0 18.983 0H7.017C6.455 0 6 .431 6 .963V2H2C.456 2 0 2.456 0 4v24c0 2.565 1.33 4 4 4h18c2.67 0 4-1.434 4-4V4c0-1.544-.456-2-2-2h-4zm-2 1.746A342.392 342.392 0 0117.997 6C11.333 5.997 8 5.997 8 6V2h10v1.746zM6 4H2v24c.037 1.472.49 1.961 2 2h18c1.51-.039 1.963-.528 2-2V4h-4v2.075c0 .51-.215 1-.597 1.362-.382.361-.9.564-1.44.563H8.038C6.914 8 6 7.134 6 6.075V4zm7 22c.552 0 1-.47 1-1.052V20h4.948C19.53 20 20 19.552 20 19s-.47-1-1.052-1H14v-4.948C14 12.47 13.552 12 13 12s-1 .47-1 1.052V18H7.052C6.47 18 6 18.448 6 19s.47 1 1.052 1H12v4.948c0 .581.448 1.052 1 1.052z" _fill="#1D3861"/>'
  }
})
