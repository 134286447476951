import { newrelic } from '@sword-health/ui-core';

import Vue from 'vue';
import SvgIcon from 'vue-svgicon';
import { InlineSvgPlugin } from 'vue-inline-svg';
import '@/plugins/http-configs';
import '@/plugins/vuelidate-config';
import dayjs from '@/plugins/dayjs';
import '@/plugins/notify';
import firebase from 'firebase/app';

import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './scripts/i18n-config';

// Service Worker and Push Notifications
import firebaseConfig from './configs/firebase';
import 'firebase/messaging';

import '@/icons';
import '@/styles/main.scss';

newrelic.listen();

Vue.use(InlineSvgPlugin);

// eslint-disable-next-line no-unused-vars
Vue.config.errorHandler = function errorHandler(err, vm, info) {
  newrelic.noticeError(err, { info });
  console.error(err);
};

Vue.config.productionTip = false;

Vue.use(SvgIcon, {
  tagName: 'sh-icon',
});

// Initialize Firebase
if (firebase.messaging.isSupported()) {
  firebase.initializeApp(firebaseConfig);
  firebase.messaging();
}

Vue.use(dayjs);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
