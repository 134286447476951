/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clinical-records': {
    width: 36,
    height: 34,
    viewBox: '0 0 36 34',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M25.411.9c.534 0 .977.385 1.06.89l.014.172-.17 9.852 2.556-2.523a3.055 3.055 0 014.044-.204l.23.204 1.07 1.057a2.963 2.963 0 01.208 3.997l-.207.227-7.731 7.59v9.876c0 .528-.39.966-.9 1.048l-.174.014H1.974c-.533 0-.977-.385-1.06-.89L.9 32.038V11.272l.008-.12.035-.161.04-.114.07-.14.104-.15.101-.105 10.53-9.313.146-.104.167-.081.214-.058.133-.018.022-.005L25.411.9zM13.579 2.924v8.35c0 .527-.39.965-.9 1.047l-.175.014-9.455-.1-.1 18.741 21.388.1.17-6.92-2.71 2.676c-.092.09-.198.163-.315.216l-.181.063-5.131 1.282a1.083 1.083 0 01-1.024-.279 1.055 1.055 0 01-.311-.83l.03-.183 1.305-5.06a1.05 1.05 0 01.158-.344l.124-.145 7.885-7.743-.001-10.787-10.757-.098zm4.607 19.898l10.082-9.936 2.38 2.348-10.18 9.909-3.238.794.956-3.116zm12.34-12.139l-.808.631 2.308 2.418.67-.663a.855.855 0 00.112-1.083l-.111-.135-1.07-1.057a.887.887 0 00-1.1-.11zM10.204 5.421l1.226-1.087v5.977l-6.907-.1 5.681-4.79z" _fill="#1D3861"/>'
  }
})
