/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 16,
    height: 16,
    viewBox: '0 0 13 13',
    data: '<linearGradient id="svgicon_check_a" gradientUnits="userSpaceOnUse" x1="8.667" y1="6" x2="4.333" y2="6"><stop offset="0"/><stop offset="1"/></linearGradient><path pid="0" d="M4 11c-.3 0-.6-.1-.8-.4L.2 7c-.3-.4-.3-1 .2-1.4.4-.4 1.1-.3 1.4.1l2.3 2.8 7.2-7.2c.4-.4 1-.4 1.4 0s.4 1 0 1.4l-8 8c-.2.2-.4.3-.7.3z" _fill="url(#svgicon_check_a)"/>'
  }
})
