import { USER_ROLES, CHANNEL_TYPES } from '@/configs/constants';

function mergeMemberAndChannel(member, channel, patient = {}) {
  const { url, lastMessage } = channel;
  const { nickname } = member;
  const { user } = patient;
  console.log(user, patient, member, channel, lastMessage);

  return {
    url,
    nickname,
    lastMessage,
  };
}

/**
 * Return the user id that is extracted from the members sendbird ID.
 *
 * It can parse a sendbirdID with the format role-environment-userId
 * or role-environment-userUUID, but it will only return a value if it's a user Id.
 * In the case that the sendbirdId only contains the user's UUID, this will return null
 *
 * this works for formats like 'pt-staging-1234' and also
 * 'member-staging-cf8994be-26c2-45bc-9a26-e6fefee8bf59'
 * @param {String}          sendbirdId
 * @returns {Number|null}
 */
function sendbirdIdToSwordId(sendbirdId) {
  // eslint-disable-next-line no-unused-vars
  const [ role, environment, ...swordId ] = sendbirdId.split('-');
  const id = Number(swordId.join('-'));
  return Number.isNaN(id) ? null : id;
}

/**
 * Filter member list by metatadata key
 * @param {Array<Member>}   members
 * @param {String}          key
 */
const filterMembersByMetadataKey = (members, key, value) => members.filter(m => {
  const { metaData } = m;
  if (!m || !metaData) {
    return false;
  }

  return key in metaData && metaData[key] === value;
});

/**
 * Filter member list by metatadata key
 * @param {Array<Member>}   members
 * @param {String}          key
 */
const getFirstPatientFromMembersList = members => {
  if (!members || !members.length) {
    return {};
  }

  const membersThatArePatients = filterMembersByMetadataKey(
    members, 'role', USER_ROLES.MEMBER,
  );

  return membersThatArePatients[0] || {};
};

/**
 * Get role of member from metadata. If there is no metadata,
 * get the role from id.
 * @param {Member}   member
 * @return {String}
 */
const getMemberRole = member => {
  if (member.metaData && 'role' in member.metaData) {
    return member.metaData?.role || '';
  }

  const [ roleFromId ] = member.userId.split('-');
  return roleFromId || '';
};

/**
 * Returns true if sendbird member is a PT
 * @param {Member}   member
 * @return {Boolean}
 */
const isPT = member => {
  const role = getMemberRole(member);
  return Boolean(role && role === USER_ROLES.PT);
};

/**
 * Returns true if sendbird member is a sword member
 * @param {Member}   member
 * @return {Boolean}
 */
const isMember = member => {
  const role = getMemberRole(member);
  return Boolean(role && role === USER_ROLES.MEMBER);
};

/**
 * Get channels data.
 * @param {Channel}   channel
 * @return {Object}
 */
const getChannelData = channel => {
  if (!channel || !channel.data) {
    return {};
  }

  let { data } = channel;
  try {
    data = JSON.parse(channel.data);
  } catch (error) {
    console.log(`failed to parse channel data for channel with url ${channel.url}`);
  }

  return data || {};
};

/**
 * Returns true if sendbird channel is of type Preventive
 * @param {Channel}   channel
 * @return {Boolean}
 */
const isPreventiveChannel = channel => {
  const { program } = getChannelData(channel);
  return Boolean(program && program === CHANNEL_TYPES.PREVENTIVE);
};

export default {
  mergeMemberAndChannel,
  sendbirdIdToSwordId,
  filterMembersByMetadataKey,
  getFirstPatientFromMembersList,
  getMemberRole,
  isPT,
  isMember,
  getChannelData,
  isPreventiveChannel,
};
