import Vue from 'vue';

import { URLS } from '@/configs/constants';

export default {
  namespaced: true,
  state: {
    patients: [],
    institutions: [],
    memberProfile: null,
  },
  getters: {
    getPatients: state => state.patients,
    getMemberProfile: state => state.memberProfile,
  },
  mutations: {
    appendToPatientList(state, chunk) {
      // Adding chunk of new patients to current patients list
      state.patients = state.patients.concat(chunk);
    },
    setMemberProfile(state, memberProfile) {
      state.memberProfile = memberProfile;
    },
  },
  actions: {
    async fetchMemberInfo({ rootGetters }, memberId) {
      if (!memberId) {
        return null;
      }

      const token = rootGetters['user/auth/getAuth'].accessToken;
      const { data } = await Vue.$http.get(
        `${URLS.THERAPIST_API}v1/chat/member/${memberId}`,
        { headers: { Authorization: token } },
      );
      return data;
    },
    async fetchMemberAccount({ rootGetters }, memberId) {
      if (!memberId) {
        return Promise.reject(new Error('[chat-list] Can not fetch member account with member id reference'));
      }
      try {
        const token = rootGetters['user/auth/getAuth'].accessToken;
        const { data: { account } } = await Vue.$http.get(`${URLS.THERAPIST_API}v1/member/${memberId}`, { headers: { Authorization: token } });
        return Promise.resolve(account);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchPatientMemberAccountPreferences({ dispatch }, memberId) {
      try {
        const memberAccountData = await dispatch('fetchMemberAccount', memberId);
        return Promise.resolve({
          is_automatic_chat_messages_enabled: memberAccountData.is_automatic_chat_messages_enabled,
        });
      } catch (e) {
        return Promise.reject(e);
      }
    },
    async fetchPatients({ dispatch, commit }, accountIDs) {
      try {
        console.log('[chat-list] loading patients');
        const patients = await dispatch('fetchPatientChunk', accountIDs);
        commit('appendToPatientList', patients);

        console.log(`[chat-list] finished loading ${patients?.length} patients`);
        return patients;
      } catch (e) {
        console.error('[chat-list] Failed on requesting patients list', e);
        return [];
      }
    },
    async fetchPatientChunk({ rootGetters }, accountIDs) {
      const token = rootGetters['user/auth/getAuth'].accessToken;
      const params = {
        account_uuids: accountIDs.join(','),
        fields: 'picture,gender,institution,hasApp,memberAccountId,uuid',
        is_active: true,
      };

      const { data: { accounts } } = await Vue.$http.get('/api/v3/patient-fields', { params, headers: { Authorization: token } });
      return accounts.map(acc => acc.programs.find(p => p.is_current === true || p.is_active)); // keep compatibility
    },
    async fetchPatientSummarySideBarInfo({ commit, getters, rootGetters }, patientId) {
      const storedMemberProfileId = getters.getMemberProfile?.profile.user_id;
      const activeMemberDataAlreadyStored = rootGetters['chat/activeChannel/isMemberId'](storedMemberProfileId);
      if (activeMemberDataAlreadyStored) {
        return;
      }
      commit('setMemberProfile', null);
      try {
        commit('status/setLoading', 'memberProfile', { root: true });
        const patients = getters.getPatients;
        const activeMemberUuid = patients.find(patient => patient && patient.id === patientId)?.uuid;
        const { data } = await Vue.$http.get(
          `${URLS.THERAPIST_API}v1/patients/summary-side-bar`,
          {
            params: { patient_uuid: activeMemberUuid },
            headers: { Authorization: rootGetters['user/auth/getAuth'].accessToken },
          },
        );
        const sameActiveChannelWasKept = rootGetters['chat/activeChannel/isMemberId'](patientId);
        if (!sameActiveChannelWasKept) {
          return;
        }
        commit('setMemberProfile', data);
      } catch (error) {
        console.error('[member-summary-side-bar] Error fetching patient summary.', error);
      } finally {
        commit('status/setReady', 'memberProfile', { root: true });
      }
    },
  },
};
