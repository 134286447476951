/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'sound': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.978 11.207a.85.85 0 011.19-.173 7.663 7.663 0 013.085 6.14 7.663 7.663 0 01-3.085 6.141.85.85 0 11-1.017-1.363 5.961 5.961 0 002.401-4.777 5.962 5.962 0 00-2.401-4.778.85.85 0 01-.173-1.19z" _fill="#1D3861"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M22.7 7.088a.85.85 0 011.196-.13 13.11 13.11 0 014.903 10.216 13.11 13.11 0 01-4.903 10.217.85.85 0 11-1.067-1.326 11.409 11.409 0 004.268-8.89c0-3.452-1.568-6.72-4.268-8.89a.85.85 0 01-.13-1.197zM12.428 5.37c1.218-1.218 3.3-.355 3.3 1.367V26.26c0 1.722-2.082 2.585-3.3 1.367L7.633 22.83a.232.232 0 00-.164-.068H5.133a1.934 1.934 0 01-1.934-1.934v-8.662c0-1.068.866-1.934 1.934-1.934h2.336c.061 0 .12-.024.164-.068l4.795-4.795zm1.599 1.367c0-.206-.25-.31-.396-.164L8.836 11.37a1.934 1.934 0 01-1.367.566H5.133a.232.232 0 00-.232.232v8.662c0 .128.104.232.232.232h2.336c.512 0 1.004.204 1.367.567l4.795 4.795a.232.232 0 00.396-.164V6.737z" _fill="#1D3861"/>'
  }
})
