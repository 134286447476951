/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'text': {
    width: 16,
    height: 16,
    viewBox: '0 0 31 36',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M22.774.957l7.082 6.956c.195.191.304.452.304.725v22.906c0 2.09-1.7 3.79-3.79 3.79H4.158c-2.09 0-3.79-1.7-3.79-3.79V4.456c0-2.09 1.7-3.79 3.79-3.79h17.905c.267 0 .522.105.712.291zm3.887 6.665l-3.583-3.519V7.3c0 .178.145.322.323.322h3.26zm-.29 25.68H4.157a1.76 1.76 0 01-1.759-1.758V4.456c0-.97.79-1.758 1.759-1.758h16.89V7.3A2.357 2.357 0 0023.4 9.654h4.728v21.89a1.76 1.76 0 01-1.758 1.758zM7.997 16.782h14.318a1.016 1.016 0 010 2.03H7.997a1.016 1.016 0 110-2.03zm0 6.093h11.674a1.015 1.015 0 100-2.031H7.997a1.016 1.016 0 100 2.03zm0 2.031H21.08a1.016 1.016 0 010 2.031H7.997a1.016 1.016 0 110-2.03z" _fill="#1D3861"/>'
  }
})
