import SendBird from 'sendbird';
import SendBirdSyncManager from 'sendbird-syncmanager';
import { SENDBIRD_SYNC_MANAGER } from '@/configs/constants';

let instance = null;

class SendBirdSyncManagerAction {
  constructor() {
    if (instance) {
      return instance;
    }

    this.manager = null;
    this.options = null;

    instance = this;
  }

  /**
   * Initialize and set up the database for SyncManager
   * @param {string}  userId
   * @returns {Promise<void>}
   */
  async setup(userId) {
    SendBirdSyncManager.sendBird = SendBird.getInstance();
    this.setOptions();
    await SendBirdSyncManager.setup(userId, this.options);
    this.manager = SendBirdSyncManager.getInstance();
  }

  setOptions() {
    this.options = new SendBirdSyncManager.Options();
    const {
      MESSAGE_COLLECTION_CAPACITY,
      MESSAGE_RESEND_POLICY,
      AUTOMATIC_MESSAGE_RESEND_RETRY_COUNT,
      MAX_FAILED_MESSAGE_COUNT_PER_CHANNEL,
      FAILED_MESSAGE_RETENTION_DAYS,
    } = SENDBIRD_SYNC_MANAGER;
    this.options.messageCollectionCapacity = MESSAGE_COLLECTION_CAPACITY;
    this.options.messageResendPolicy = MESSAGE_RESEND_POLICY;
    this.options.automaticMessageResendRetryCount = AUTOMATIC_MESSAGE_RESEND_RETRY_COUNT;
    this.options.maxFailedMessageCountPerChannel = MAX_FAILED_MESSAGE_COUNT_PER_CHANNEL;
    this.options.failedMessageRetentionDays = FAILED_MESSAGE_RETENTION_DAYS;
  }

  static getInstance() {
    return new SendBirdSyncManagerAction();
  }
}

export default SendBirdSyncManagerAction;
