import SendbirdCallAction from '@/scripts/sendbird-integration/SendbirdCallAction';

const sbcAction = new SendbirdCallAction();

export default {
  namespaced: true,

  state: {
    localMediaAccess: null,
    createdMediaAccesses: [],
    callListeners: null,

    hasMediaPermission: true,
    isAudioEnabled: true,
    isVideoEnabled: true,
    areDevicesLoaded: false,

    availableMediaDevices: {
      videoInput: [],
      audioInput: [],
      audioOutput: [],
    },
    videoStream: null,
  },

  getters: {
    getLocalMediaAccess: state => state.localMediaAccess,
    getCreatedMediaAccesses: state => state.createdMediaAccesses,
    getCallListeners: state => state.callListeners,

    hasMediaPermission: state => state.hasMediaPermission,
    isAudioEnabled: state => state.isAudioEnabled,
    isVideoEnabled: state => state.isVideoEnabled,
    areDevicesLoaded: state => state.areDevicesLoaded,

    getAvailableMediaDevices: state => state.availableMediaDevices,
    getVideoStream: state => state.videoStream,
  },

  mutations: {
    resetMedia(state) {
      state.localMediaAccess = null;
      state.createdMediaAccesses = [];
      state.callListeners = null;
      state.areDevicesLoaded = false;
    },
    setLocalMediaAccess(state, localMediaAccess) {
      state.localMediaAccess = localMediaAccess;
      if (localMediaAccess) {
        state.createdMediaAccesses.push(localMediaAccess);
      }
      if (localMediaAccess?.fromCall) {
        state.callListeners = localMediaAccess._listeners;
      }
    },
    setMediaPermission(state, hasMediaPermission) {
      state.hasMediaPermission = hasMediaPermission;
    },
    setAudioEnabled(state, isAudioEnabled) {
      state.isAudioEnabled = isAudioEnabled;
    },
    setVideoEnabled(state, isVideoEnabled) {
      state.isVideoEnabled = isVideoEnabled;
    },
    setLoadedDevices(state, areDevicesLoaded) {
      state.areDevicesLoaded = areDevicesLoaded;
    },
    setAvailableMediaDevices(state, availableMediaDevices) {
      state.availableMediaDevices = availableMediaDevices;
    },
    setSelectedMediaDevices(state, selectedMediaDevices) {
      state.selectedMediaDevices = selectedMediaDevices;
    },
    setVideoStream(state, videoStream) {
      state.videoStream = videoStream;
    },
    removeVideoStream(state) {
      state.videoStream = null;
    },
  },

  actions: {
    async updateMediaPermission({ getters, commit }) {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const videoInputs = devices.filter(device => device.kind === 'videoinput');
      const audioInputs = devices.filter(device => device.kind === 'audioinput');

      const { hasPermission } = getters.getLocalMediaAccess;
      console.log('[call-media] has sendbird permission: ', hasPermission);
      const hasAudioLabel = audioInputs.every(device => device.label);
      console.log('[call-media] has audio label: ', hasAudioLabel);
      const hasVideoLabel = videoInputs.every(device => device.label);
      console.log('[call-media] has video label: ', hasVideoLabel);

      commit('setMediaPermission', hasPermission && hasAudioLabel && hasVideoLabel);
    },
    async updateAvailableMediaDevices({ commit }) {
      const devices = await navigator.mediaDevices.enumerateDevices();

      const videoInputs = devices.filter(device => device.kind === 'videoinput');
      const audioInputs = devices.filter(device => device.kind === 'audioinput');
      const audioOutputs = devices.filter(device => device.kind === 'audiooutput');

      commit('setAvailableMediaDevices', {
        videoInput: videoInputs.filter(device => device.label),
        audioInput: audioInputs.filter(device => device.label),
        audioOutput: audioOutputs.filter(device => device.label),
      });
    },
    getCurrentMediaDevices: () => sbcAction.getCurrentMediaDevices(),

    toggleLocalAudio({ getters, commit }, isNewAudioEnabled) {
      const isAudioEnabled = isNewAudioEnabled ?? !getters.isAudioEnabled;
      commit('setAudioEnabled', isAudioEnabled);
    },
    async toggleLocalVideo({ getters, commit, dispatch }, isNewVideoEnabled) {
      const isVideoEnabled = isNewVideoEnabled ?? !getters.isVideoEnabled;
      commit('setVideoEnabled', isVideoEnabled);

      if (isVideoEnabled) {
        await dispatch('turnCamera', { on: true });
      } else {
        await dispatch('turnCamera', { on: false });
      }
    },
    async togglePreviewVideo({ getters, commit, dispatch }, isNewVideoEnabled) {
      await dispatch('toggleLocalVideo', isNewVideoEnabled);

      if (getters.isVideoEnabled) {
        getters.getLocalMediaAccess.on('streamChanged', stream => {
          console.log('[call-media] preview video stream changed');
          dispatch('updateMediaPermission');
          commit('setLoadedDevices', true);
          commit('setVideoStream', stream);
        });
      } else {
        commit('removeVideoStream');
      }
    },
    async turnCamera({ getters, commit, dispatch }, { on }) {
      console.log(`[call-media] turning ${on ? 'on' : 'off'} the camera`);
      if (getters.getLocalMediaAccess) getters.getLocalMediaAccess.dispose();

      let newLocalMediaAccess = sbcAction.useMedia({ audio: true, video: on });
      if (getters.getCallListeners) {
        newLocalMediaAccess = await dispatch('mergeMediaAccess', newLocalMediaAccess);
      }
      commit('setLocalMediaAccess', newLocalMediaAccess);
    },
    mergeMediaAccess({ getters }, localMediaAccess) {
      console.log('[call-media] merging call listeners');
      const callListeners = getters.getCallListeners;
      const newLocalMediaAccess = Object.assign(localMediaAccess);

      // Copy callListeners 'partially deeply' so state.callListener is not affected when newLocalMediaAccess.on('streamChanged')
      newLocalMediaAccess._listeners = {
        audioOutputChanged: [ callListeners.audioOutputChanged[0] ],
        streamChanged: [ callListeners.streamChanged[0] ],
      };
      return newLocalMediaAccess;
    },
    async disposeMediaAccess({ getters, commit }) {
      getters.getCreatedMediaAccesses.forEach(mediaAccess => {
        console.log('[call-media] disposing media access');
        mediaAccess.dispose();
      });
      commit('resetMedia');
    },
    saveMediaDevices(_, selectedDevices) {
      sbcAction.saveMediaDevices(selectedDevices);
    },
  },
};
