import SendBirdAction from '@/scripts/sendbird-integration/SendBirdAction';
import SendBirdSyncManagerEvent from '@/scripts/sendbird-integration/SendBirdSyncManagerEvent';
import { findMessageIndex } from '@/scripts/sendbird-integration/sendbird-utils';
import { MESSAGES_TYPES } from '@/configs/constants';

export default {
  namespaced: true,
  state: {
    activeChannel: null,
    messages: [],
    member: null,
  },
  getters: {
    getActiveChannel: state => state.activeChannel,
    getMessages: state => state.messages,
    getMember: state => state.member,
    getMemberId: state => state.member?.patient?.id,
    isMemberId: state => id => !!id && state.member?.patient?.id === id,
  },
  mutations: {
    setActiveChannel(state, channel) {
      state.activeChannel = channel;
    },
    setMessages(state, messages) {
      // Filter messages from admins because they were breaking chat rendering
      // @TODO, make admin messages work
      state.messages = messages.filter(message => message.messageType !== 'admin');
    },
    pushMessage(state, message) {
      state.messages.push(message);
    },
    updateMessageByReqId(state, message) {
      const index = state.messages.findIndex(m => m.reqId === message.reqId);
      if (index >= 0) {
        state.messages.splice(index, 1, message);
      }
    },
    setMember(state, member = {}) {
      const defaultPreferences = {
        preferences: {
          smsBlocked: null,
        },
      };
      // TODO Tmp failsafe until all members date fully compatible with the app requirements
      const mergedPreferences = { ...defaultPreferences, ...member.preferences };
      state.member = { ...member, preferences: mergedPreferences };
    },
  },
  actions: {
    async setActiveChannelByUrl({ rootGetters, commit, dispatch }, url) {
      console.log(`[chat] setting active chat to ${url}`);
      const sbAction = SendBirdAction.getInstance();
      const channel = await sbAction.getChannel(url);
      commit('setActiveChannel', channel);
      await dispatch('setActiveChannelMember');

      if (channel.isGroupChannel() && !!channel.unreadMessageCount) {
        sbAction.markAsRead(channel);
        const unreadMessages = rootGetters['chat/getCounters'].unread;
        const updatedUnreadMessages = unreadMessages > 0 ? unreadMessages - 1 : 0;
        commit('chat/setCounters', { counter: updatedUnreadMessages, counterType: MESSAGES_TYPES.UNREAD }, { root: true });
      }
      return channel;
    },
    async getGroupCallInfo({ getters, dispatch, commit }) {

      const channel = getters.getActiveChannel;

      if (!channel) {
        return null;
      }
      const program = getters.getMember.patient;

      if (!program) {
        console.log('[sendbird-group-call] Can\'t get member info without program');
        return null;
      }

      const memberId = program.id;
      try {
        console.log(`[sendbird-group-call] getting video call room info for channel ${channel.url}`);

        const member = await dispatch('patient/fetchMemberInfo', memberId, { root: true });

        const updatedMemberBody = {
          ...getters.getMember,
          canReceiveSms: member.can_receive_sms,
          preferredName: member.preferred_name,
          preferences: {
            smsBlocked: !!member.sms_opt_out,
          },
        };

        commit('setMember', updatedMemberBody);

        if (!member) {
          console.log(`[sendbird-group-call] unable to fetch member info for memberId: ${memberId}`);
          return null;
        }

        const { video_call_room_id: groupCallId, video_url: publicGroupCallToken } = member;

        return { groupCallId, publicGroupCallToken };
      } catch (error) {
        console.log('[sendbird-group-call] failed to get group call info', error);
        return null;
      }
    },
    async setActiveChannelMember({ getters, rootGetters, commit, dispatch }) {
      const { url: activeChannelUrl } = getters.getActiveChannel || {};
      const fetchedChatMembers = rootGetters['chat/getMembers'];


      if (!activeChannelUrl || !fetchedChatMembers.length) {
        return;
      }

      const activeMember = fetchedChatMembers.find(m => m.channel.url === activeChannelUrl);

      if (!activeMember?.account && activeMember?.patient?.id) {
        try {
          activeMember.account = await dispatch('patient/fetchPatientMemberAccountPreferences', activeMember.patient.id, { root: true });
        } catch (e) {
          console.error(e);
        }
      }
      commit('setMember', activeMember);
    },
    async resetActiveChannel({ getters, commit }) {
      const activeUrl = getters.getActiveChannel && getters.getActiveChannel.url;
      if (!activeUrl) {
        return null;
      }

      const sbAction = SendBirdAction.getInstance();
      const channel = await sbAction.getChannel(activeUrl);
      commit('setActiveChannel', channel);
      return channel;
    },
    deleteActiveChannel({ commit }) {
      commit('setActiveChannel', null);
    },
    resetChannelMessages({ getters, commit }) {
      const activeChannel = getters.getActiveChannel;
      if (!activeChannel) {
        console.log('[messages] trying to reset channel messages with no active channel');
        return;
      }
      commit('setMessages', []);
    },
    async fetchChannelMessages({ getters }) {
      const activeChannel = getters.getActiveChannel;
      if (!activeChannel) {
        console.log('[messages] trying to get channel messages with no active channel');
        return;
      }

      try {
        console.log(`[messages] getting messages for channel ${activeChannel.url}`);
        const messageCollection = SendBirdSyncManagerEvent.getMessageCollection();
        await Promise.all([
          messageCollection.fetchSucceededMessages('prev'),
          messageCollection.fetchSucceededMessages('next'),
          messageCollection.fetchFailedMessages(),
        ]);
        console.log(`[messages] done getting messages for channel ${activeChannel.url}`);
      } catch (error) {
        console.log(`[messages] error getting messages for channel ${activeChannel.url}`, error);
        throw error;
      }
    },
    setChannelMessages({ getters, commit }, { channel, messages }) {
      const activeChannel = getters.getActiveChannel;
      if (!activeChannel || activeChannel.url !== channel.url) {
        console.log('[messages] trying to set messages on a channel that is not active');
        return;
      }
      commit('setMessages', messages);
    },
    sendMessage({ getters, dispatch }, { messageText, messageFiles }) {
      const activeChannel = getters.getActiveChannel;
      if (!activeChannel) {
        console.log('[messages] trying to send message with no active channel');
        return;
      }

      const sbAction = SendBirdAction.getInstance();
      const messageCollection = SendBirdSyncManagerEvent.getMessageCollection();

      if (messageText) {
        const tempMessage = sbAction.sendUserMessage(activeChannel, messageText, (message, error) => {
          if (error) {
            console.log(`[messages] error sending text messages for channel ${activeChannel.url}`, message, error);
          } else {
            dispatch('chat/moveChannelAndMemberToTop', activeChannel, { root: true });
          }
          messageCollection.handleSendMessageResponse(error, message);
        });
        messageCollection.appendMessage(tempMessage);
      }

      if (messageFiles.length) {
        const tempMessages = sbAction.sendFileMessages(activeChannel, messageFiles, (message, error) => {
          if (error) {
            console.log(`[messages] error sending file messages for channel ${activeChannel.url}`, message, error);
          } else {
            dispatch('chat/moveChannelAndMemberToTop', activeChannel, { root: true });
          }
          messageCollection.handleSendMessageResponse(error, message);
        });
        tempMessages.forEach(message => {
          messageCollection.appendMessage(message);
        });
      }

      // Add message to stack anyway, callback will remote it if necessary
      if (activeChannel.isGroupChannel()) {
        activeChannel.endTyping();
      }
    },
    updateMessages({ getters, commit }, { channel, messages: updatedMessages }) {
      const activeChannel = getters.getActiveChannel;
      if (!activeChannel || activeChannel.url !== channel.url) {
        console.log('[messages] trying to update messages on a channel that is not active');
        return;
      }

      const channelMessages = getters.getMessages;
      updatedMessages.forEach(updatedMessage => {
        const index = findMessageIndex(updatedMessage, channelMessages);
        if (index >= 0) {
          channelMessages.splice(index, 1, updatedMessage);
        }
      });

      commit('setMessages', channelMessages);
      console.log(`[messages] updated message on channel ${channel.url}`, updatedMessages);
    },
    removeMessages({ getters, commit }, { channel, messages: removedMessages }) {
      const activeChannel = getters.getActiveChannel;
      if (!activeChannel || activeChannel.url !== channel.url) {
        console.log('[messages] trying to remove messages on a channel that is not active');
        return;
      }

      const channelMessages = getters.getMessages;
      removedMessages.forEach(removedMessage => {
        const index = findMessageIndex(removedMessage, channelMessages);
        if (index >= 0) {
          channelMessages.splice(index, 1);
        }
      });

      commit('setMessages', channelMessages);
      console.log(`[messages] removed message on channel ${channel.url}`, removedMessages);
    },
  },
};
