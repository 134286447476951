<template>
  <div id="app">
    <router-view></router-view>
    <theme-selector v-if="showThemeSelector"
                    :current-theme="currentTheme"
                    @change-theme="selectTheme" />
  </div>
</template>

<script>
import { selectTheme } from '@ui-kit/themes';
import themeSelector from '@ui-kit/dev-utils/theme-selector';

export default {
  name: 'webChat',
  mixins: [ themeSelector.commands ],
  components: {
    themeSelector: themeSelector.component,
  },
  data() {
    return {
      showThemeSelector: process.env.NODE_ENV === 'development' && false,
    };
  },
  created() {
    selectTheme();
  },
};
</script>

<style lang="scss">
html,
body {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}

#app {
  width: 100%;
  height: 100%;
  font-family: avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.hide-in-small-screens {
  @media screen and (max-width: $max-tablet-size) {
    display: none !important;
  }
}
</style>
