import Vue from 'vue';
import configs from '@/configs';

const auth = {
  namespaced: true,
  state: {
    loggedIn: false,
    // If redirectToLogin is true, will redirect page to Clinical Portal login page
    redirectToLogin: false,
    environment: null,
    accessToken: null,
    refreshToken: null,
    sendbirdToken: null,
  },
  getters: {
    getAuth: state => ({
      accessToken: state.accessToken,
      refreshToken: state.refreshToken,
      sendbirdToken: state.sendbirdToken,
    }),
    shouldRedirectToLogin: state => state.redirectToLogin,
    isLoggedIn: state => state.loggedIn,
    getUserEnv: state => state.environment,
    accessToken: state => state.accessToken,
    refreshToken: state => state.refreshToken,
    sendbirdToken: state => state.sendbirdToken,
  },
  mutations: {
    setAsLoggedIn(state, loggedIn) {
      state.loggedIn = loggedIn;
    },
    setAuthTokens(state, tokensPayload) {
      if ('accessToken' in tokensPayload) {
        state.accessToken = tokensPayload.accessToken;
        state.loggedIn = true;
      }
      if ('refreshToken' in tokensPayload) {
        state.refreshToken = tokensPayload.refreshToken;
      }
    },
    setSendbirdToken(state, token) {
      state.sendbirdToken = token;
    },
    deleteTokens(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.sendbirdToken = null;
    },
    assignNewAccessToken(state, token) {
      if (!token) {
        return;
      }
      state.accessToken = token;
    },
    // Setting redirectToLogin to true will trigger a redirect to Clinical Portal login page.
    setRedirectToLogin(state, redirect) {
      state.redirectToLogin = redirect;
    },
  },
  actions: {
    clearUserTokens({ commit }) {
      commit('deleteTokens');
    },
    refreshAuthToken({ state }) {
      return Vue.$http.get('/api/v2/auth/refresh', { headers: { Authorization: state.refreshToken } });
    },
    routeUserOnAuthError({ commit }) {
      commit('setAsLoggedIn', false);

      console.log('[redirect] Redirecting user to SWORD Clinical Portal login page');
      // Redirect to Clinical Portal login page.
      window.location.href = configs.get('CLINICAL_PORTAL_LOGIN_URL');
    },
    logout({ dispatch, commit }) {
      dispatch('clearUserTokens');
      commit('setAsLoggedIn', false);
      dispatch('user/cookies/deleteAuthCookies', null, { root: true });
    },
  },
};

export default auth;
