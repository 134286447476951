/* eslint-disable no-param-reassign */
import dayjs from 'dayjs';

export default {
  install(Vue) {
    Vue.prototype.$date = dayjs;

    Vue.prototype.$date.prototype.pretty = function makeDatePrettier(d) {
      const date = dayjs(d);
      const diff = this.diff(date, 'day');
      if (!diff) {
        return this.format('h mm A');
      }
      if (diff === 1) {
        return `Yesterday, ${this.format('h mm A')}`;
      }
      return this.format('MMMM DD, YYYY h  mm A');
    };

    Vue.prototype.$date.prototype.defaultDiff = function defDiff(d) {
      const date = dayjs(d);
      const diff = this.diff(date, 'day');
      if (!diff) {
        return 'Today';
      }
      if (diff === 1) {
        return 'Yesterday';
      }
      return this.format('DD/MM');
    };

    Vue.prototype.$date.prototype.isSameDay = function isSameD(d) {
      const date = dayjs(d);
      return this.isSame(date, 'day');
    };

    Vue.prototype.$date.prototype.divide = function divideDate() {
      return {
        day: this.date(),
        month: this.month(),
        year: this.year(),
        month_name: this.format('MMMM'),
      };
    };

    Vue.prototype.$date.prototype.shToday = function checkIfIsToday(d) {
      const date = dayjs(d);
      return this.isSame(date, 'day');
    };

    Vue.prototype.$date.prototype.shYesterday = function shYesterday(d) {
      const date = dayjs(d);
      return this.subtract(1, 'day').isSame(date, 'day');
    };

    Vue.prototype.$date.prototype.shDayDiff = function shDayDiff(d = new Date()) {
      const date = dayjs(d).set('hour', 0).set('minute', 0).set('second', 0);
      const dateOrigin = dayjs(this.$d).set('hour', 0).set('minute', 0).set('second', 0);
      return dateOrigin.diff(date, 'day');
    };

    Vue.prototype.$date.prototype.diffAtLeast = function diffAtLeast(date) {
      const self = this;
      date = dayjs(date);
      return function diff(amount, frame) {
        return self.diff(date, frame) > amount;
      };
    };

    Vue.prototype.$date.prototype.laterThan = function shMoreFuture(d) {
      const timestampToTest = Math.round(this.$d.getTime() / 1000);
      const timestampRef = Math.round(dayjs(d).$d.getTime() / 1000);
      return timestampToTest > timestampRef;
    };

    Vue.prototype.$date.prototype.lastMessage = function lastMessage(d) {
      const date = dayjs(d);
      const isToday = this.shToday(date);
      const wasYesterday = this.shYesterday(date);

      if (isToday) {
        return date.format('H:mm');
      }
      if (wasYesterday) {
        return 'Yesterday';
      }
      return date.format('DD/MM');
    };

    Vue.prototype.$date.prototype.getAge = function getAge(date) {
      const currentDate = new Date();
      const birthDate = new Date(date);
      let age = currentDate.getUTCFullYear() - birthDate.getUTCFullYear();
      const month = currentDate.getMonth() - birthDate.getMonth();
      if (month < 0 || (month === 0 && currentDate.getDate() < birthDate.getDate())) {
        age -= 1;
      }
      return age;
    };
  },
};
