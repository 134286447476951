import store from '@/store';

const auth = async (to, from, next) => {
  try {
    console.log('[auth] calling auth route guard');
    await store.dispatch('user/handleCookieAuth');
    return next();
  } catch (error) {
    console.log('[auth] error while handling user auth', error);
    return next({ name: 'Error' });
  }
};

const tokenBasedAuth = async (to, from, next) => {
  const token = to.params?.roomToken;
  if (!token) {
    return next({ name: 'NotFound' });
  }

  const userHasCookies = store.getters['user/cookies/hasAuthCookies'];

  console.log('[token-auth] calling token based auth route guard');
  try {
    if (userHasCookies) {
      console.log('[token-auth] using cookie auth, instead of token auth, because user has cookies');
      await store.dispatch('user/handleCookieAuth');
      return next();
    }

    await store.dispatch('user/handleTokenAuth', token);
    return next();
  } catch (error) {
    console.log('[token-auth] error while handling token auth', error);
    return next({ name: 'Error' });
  }
};

export default {
  auth,
  tokenBasedAuth,
};
