/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'envelope': {
    width: 16,
    height: 16,
    viewBox: '0 -3 31 31',
    data: '<path pid="0" d="M3.543 22.203h23.914c.25 0 .49-.09.667-.251a.82.82 0 00.276-.607V3.235c0-.473-.422-.857-.942-.858H3.543c-.521 0-.944.384-.944.858v18.109c.001.475.423.859.944.859zM.501 3.098C.501 1.548 1.863.29 3.543.29h23.914c1.68 0 3.043 1.257 3.043 2.808v18.384c0 1.551-1.362 2.808-3.042 2.808H3.543a3.177 3.177 0 01-2.152-.822A2.702 2.702 0 01.5 21.482L.501 3.098z"/><path pid="1" d="M19.265 12.338l6.18 5.75a.955.955 0 01.035 1.366.982.982 0 01-.705.296.978.978 0 01-.67-.262l-6.299-5.864-1.64 1.44a.984.984 0 01-1.3.008l-1.595-1.397-6.337 5.81a.986.986 0 01-1.376-.05.955.955 0 01.044-1.363l6.2-5.688-6.222-5.45a.95.95 0 01-.08-1.363.985.985 0 011.377-.077l6.942 6.09c.053.038.1.077.145.122l.004.01 1.541 1.349 8.6-7.566a.99.99 0 011.377.072.955.955 0 01-.073 1.363l-6.148 5.404z"/>'
  }
})
