import Vue from 'vue';
import Vuex from 'vuex';

import user from '@/store/modules/UserStore';
import chat from '@/store/modules/ChatStore';
import call from '@/store/modules/CallStore';
import status from '@/store/modules/StatusStore';
import patient from '@/store/modules/PatientStore';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appVersion: '2.0.0',
  },
  getters: {
    appVersion: state => state.appVersion,
  },
  modules: {
    user,
    chat,
    status,
    patient,
    call,
  },
});
