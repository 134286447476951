/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'send': {
    width: 16,
    height: 16,
    viewBox: '0 0 34 34',
    data: '<path pid="0" d="M32.5 16.1L2.2 3.9c-.3-.1-.7-.1-1 .2-.3.2-.4.6-.3 1l3 11.9-3 11.9c-.1.4 0 .7.3 1 .2.2.4.2.7.2.1 0 .3 0 .4-.1l30.3-12.1c.4-.2.6-.5.6-.9s-.3-.8-.7-.9zM3.3 6.5L27 16H5.7L3.3 6.5zM5.7 18H27L3.3 27.5 5.7 18z"/>'
  }
})
