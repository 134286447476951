import Vue from 'vue';

import { URLS } from '@/configs/constants';
import SendbirdCallAction from '@/scripts/sendbird-integration/SendbirdCallAction';
import sbUtils from '@/scripts/sendbird-integration/utils';

const sbcAction = new SendbirdCallAction();

export default {
  namespaced: true,

  state: {
    userId: null,
    nickname: null,
    plainProfileUrl: null,
    videoUrl: null,
    friendName: null,
    roomId: null,
    room: {},
    memberOnCallAccountUuid: undefined,
    callType: undefined,
  },

  getters: {
    getSendbirdId: state => state.userId,
    getRoomId: state => state.roomId,
    getActiveRoom: state => state.room,
    getLocalParticipant: state => state.room?.localParticipant ?? {},
    getMemberOnCallAccountUuid: state => state.memberOnCallAccountUuid,
    getCallType: state => state.callType,
  },

  mutations: {
    setSendbirdCallUser(state, payload) {
      Object.keys(payload).forEach(key => {
        if (key in state) {
          Vue.set(state, key, payload[key]);
        }
      });
    },
    setRoomId(state, id) {
      state.roomId = id;
    },
    setActiveRoom(state, room) {
      state.room = room;
    },
    setMemberOnCallAccountUuid(state, accountUuid) {
      state.memberOnCallAccountUuid = accountUuid;
    },
    setCallType(state, callType) {
      state.callType = callType;
    },
  },

  actions: {
    async updateActiveRoom({ getters, commit }, roomId) {
      const id = roomId || getters.getRoomId;
      console.log(`[sendbird-group-call] getting room for room id ${id}`);
      const room = await sbcAction.fetchRoomById(id);
      commit('setActiveRoom', room);
      return room;
    },
    async reassigneActiveRoom({ commit }, room) {
      commit('setActiveRoom', room);
      return room;
    },
    async fetchRoomInfo(_, roomToken) {
      console.log(`[sendbird-group-call] Exchaging token for room info. Token: ${roomToken}`);
      const { data } = await Vue.$http.get(`${URLS.MEMBER_API}v1/video-call/${roomToken}`);
      return data;
    },
    async updateTokenFromRoomId({ commit, dispatch }, token) {
      const roomInfo = await dispatch('fetchRoomInfo', token);
      const { video_call_room_id: roomId } = roomInfo;
      commit('setRoomId', roomId);
      return roomId;
    },
    async exchangeTokenForRoomInfo({ commit, dispatch }, token) {
      const userInfo = await dispatch('fetchRoomInfo', token);
      const {
        nickname,
        sendbird_id: userId,
        access_token: accessToken,
        video_call_room_id: videoCallRoomId,
      } = userInfo;

      commit('user/auth/setSendbirdToken', accessToken, { root: true });
      commit('chat/setSendbirdUser', { userId, nickname }, { root: true });
      commit('setRoomId', videoCallRoomId);
      return userInfo;
    },
    async fetchMembersOnCall({ rootGetters }, patientIds) {
      const numOfPatientIds = patientIds.length;

      if (numOfPatientIds) {
        const token = rootGetters['user/auth/getAuth'].accessToken;

        const params = {
          patient_ids: patientIds.join(','),
          fields: 'memberAccountId',
        };

        const { data } = await Vue.$http.get('/api/v2/patient-fields', { params, headers: { Authorization: token } });
        return data.patients;
      }

      return [];
    },
    async updateMemberOnCallAccountUuid({ commit, dispatch }, participants) {
      const validPatientIds = participants
        .map(participant => {
          const sendbirdUserId = participant.user?.userId;
          if (sendbirdUserId?.includes('member')) {
            return sbUtils.sendbirdIdToSwordId(sendbirdUserId);
          }

          return undefined;
        })
        .filter(userId => Number.isFinite(userId));

      const membersOnCall = await dispatch('fetchMembersOnCall', validPatientIds);

      let accountUuid;

      if (membersOnCall.length) {
        accountUuid = membersOnCall[0].member_account_id;
      }

      commit('setMemberOnCallAccountUuid', accountUuid);

      return accountUuid;
    },
    updateCallType({ commit }, callType) {
      commit('setCallType', callType);
      return callType;
    },
  },
};
