/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'member-profile': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20 4C11.153 4 4 11.153 4 20c0 8.847 7.153 16 16 16 8.847 0 16-7.153 16-16 0-8.847-7.153-16-16-16zm0 2c7.695 0 14 6.42 14 14.258 0 2.832-.834 5.476-2.225 7.742-2.596-2.55-6.768-4.06-11.775-4.06-5.007 0-9.179 1.416-11.775 4.06C6.835 25.734 6 23.09 6 20.258 6 12.42 12.305 6 20 6zm0 28c-4.4 0-8.322-1.787-11-4.596C11.296 27.277 15.217 26 20 26s8.704 1.192 11 3.404C28.322 32.213 24.4 34 20 34zm0-12c3.313 0 6-2.636 6-6s-2.687-6-6-6-6 2.636-6 6 2.597 6 6 6zm0-10c2.167 0 4 1.787 4 4s-1.833 4-4 4-4-1.787-4-4 1.75-4 4-4z" _fill="#1D3861"/>'
  }
})
