/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'results-charts': {
    width: 16,
    height: 16,
    viewBox: '0 0 34 34',
    data: '<path pid="0" d="M28.8 3.4h-4.9V2.3c0-.6-.4-1-1-1H11.1c-.6 0-1 .4-1 1v1.1H5.2c-.6 0-1 .4-1 1v25.3c0 1.7 1.3 3.1 3 3.1h19.6c1.7 0 3-1.4 3-3.1V4.4c0-.5-.5-1-1-1zm-16.7-.1h9.8V7.6h-9.8V3.3zm15.7 26.5c0 .6-.4 1.1-1 1.1H7.2c-.5 0-1-.5-1-1.1V5.4h3.9v2.2c0 1.1.9 2 2 2h9.8c1.1 0 2-.9 2-2V5.4h3.9v24.4z"/><path pid="1" d="M21.7 12.7c-.5-.6-1.4-.4-1.7.2l-3.3 7.4-1.8-2.3c-.4-.6-1.3-.5-1.7.1l-3.9 7.2c-.3.5-.1 1.1.4 1.4s1.1.1 1.4-.4l3.2-5.8 1.9 2.4c.5.6 1.4.5 1.7-.2l3.3-7.5 2.5 2.9c.4.4 1 .5 1.4.1s.5-1 .1-1.4l-3.5-4.1z"/>'
  }
})
