export default class AuthenticationError extends Error {
  constructor({ message, payload = null, errorCode }) {
    super(message);
    this.payload = payload;
    this.errorCode = errorCode;
    this.name = 'AuthenticationError';
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthenticationError);
    }
  }
}
