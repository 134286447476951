/**
 * Possible states of a given status
 */
export const STATUS = {
  UNSET: 'unset',
  LOADING: 'loading',
  READY: 'ready',
  FAILED: 'failed',
};

export default {
  namespaced: true,
  state: {
    chat: STATUS.UNSET,
    channels: STATUS.UNSET,
    patients: STATUS.UNSET,
    messages: STATUS.UNSET,
    call: STATUS.UNSET,
    room: STATUS.UNSET,
    memberProfile: STATUS.UNSET,
  },
  getters: {
    loading: state => key => state[key] === STATUS.LOADING,
    ready: state => key => state[key] === STATUS.READY,
    failed: state => key => state[key] === STATUS.FAILED,
    unset: state => key => state[key] === STATUS.UNSET,
  },
  mutations: {
    setLoading(state, key) {
      state[key] = STATUS.LOADING;
    },
    setReady(state, key) {
      state[key] = STATUS.READY;
    },
    setFailed(state, key) {
      state[key] = STATUS.FAILED;
    },
    setUnset(state, key) {
      state[key] = STATUS.UNSET;
    },
  },
};
