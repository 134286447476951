/* eslint-disable class-methods-use-this */
import SendBirdCall from 'sendbird-calls';
import { SENDBIRD } from '@/configs/constants';

const appId = SENDBIRD.APP_ID;

let instance = null;

class SendBirdCallAction {
  constructor() {
    if (instance) {
      return instance;
    }
    this.sbc = SendBirdCall;
    this.onRinging = null;
    instance = this;
  }

  init() {
    SendBirdCall.init(appId);
    SendBirdCall.addListener(1, {
      onRinging: call => {
        console.log('Ringing', call);

        if (this.isBusy()) {
          call.end();
        } else if (!call.endResult) {
          if (this.onRinging) {
            this.onRinging(call);
          }
        }
      },
    });
  }

  async connect(userId, accessToken) {
    const user = await SendBirdCall.authenticate({ userId, accessToken });
    await SendBirdCall.connectWebSocket();
    return user;
  }

  disconnect() {
    SendBirdCall.deauthenticate();
  }

  async fetchRoomById(roomId) {
    if (!roomId) {
      return null;
    }

    try {
      const room = await SendBirdCall.fetchRoomById(roomId);
      return room;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async dial(peerId, isVideoCall = false, callOption, channelUrl, callback) {
    return new Promise((resolve, reject) => {
      SendBirdCall.dial(
        {
          userId: peerId,
          isVideoCall,
          callOption,
          sendBirdChatOptions: {
            channelUrl,
          },
        },
        (call, error) => {
          if (error) {
            reject(error);
          }

          if (callback) callback(call, error);
          resolve(call);
        },
      );
    });
  }

  isBusy() {
    return this.sbc.getOngoingCallCount() > 1;
  }

  useMedia(constraints) {
    const { audio = false, video = false } = constraints;
    return SendBirdCall.useMedia({ audio, video });
  }

  // This function doesn't update the device list when blocking them via browser
  getAvailableMediaDevices() {
    return {
      videoInput: SendBirdCall.getAvailableVideoInputDevices(),
      audioInput: SendBirdCall.getAvailableAudioInputDevices(),
      audioOutput: SendBirdCall.getAvailableAudioOutputDevices(),
    };
  }

  getCurrentMediaDevices() {
    return {
      videoInput: SendBirdCall.getCurrentVideoInputDevice(),
      audioInput: SendBirdCall.getCurrentAudioInputDevice(),
      audioOutput: SendBirdCall.getCurrentAudioOutputDevice(),
    };
  }

  saveMediaDevices(selectedDevices) {
    const currentDevices = this.getCurrentMediaDevices();
    if (selectedDevices.videoInput && selectedDevices.videoInput?.deviceId !== currentDevices.videoInput.deviceId) {
      console.log('[call-media] saving video input device');
      SendBirdCall.selectVideoInputDevice(selectedDevices.videoInput);
    }
    if (selectedDevices.audioInput && selectedDevices.audioInput.deviceId !== currentDevices.audioInput.deviceId) {
      console.log('[call-media] saving audio input device');
      SendBirdCall.selectAudioInputDevice(selectedDevices.audioInput);
    }
    if (selectedDevices.audioOutput && selectedDevices.audioOutput.deviceId !== currentDevices.audioOutput.deviceId) {
      console.log('[call-media] saving audio output device');
      SendBirdCall.selectAudioOutputDevice(selectedDevices.audioOutput);
    }
  }

  static getInstance() {
    return new SendBirdCallAction();
  }
}

export default SendBirdCallAction;
