import SendBirdSyncManager from 'sendbird-syncmanager';
import configs from '@/configs';
import { uuid4 } from '@/scripts/utils';

const MESSAGES_CHUNK_SIZE = Number(configs.get('MESSAGES_QUERY_CHUNK_SIZE'));
let messageCollection;

class SendBirdSyncManagerEvent {
  constructor(channel) {
    this.manager = SendBirdSyncManager.getInstance();
    this.key = uuid4();

    this.channel = channel;
    this._initElement();

    this.onSucceededMessageEvent = null;
    this.onPendingMessageEvent = null;
    this.onFailedMessageEvent = null;
    this.onNewMessage = null;
  }

  _initElement() {
    if (messageCollection) {
      messageCollection.remove();
    }

    messageCollection = new SendBirdSyncManager.MessageCollection(this.channel, { includeMetaArray: true });
    messageCollection.limit = MESSAGES_CHUNK_SIZE;
    const collectionHandler = new SendBirdSyncManager.MessageCollection.CollectionHandler();

    collectionHandler.onSucceededMessageEvent = (messages, action, reason) => {
      if (this.onSucceededMessageEvent) {
        this.onSucceededMessageEvent(messageCollection, messages, action, reason);
      }
    };
    collectionHandler.onPendingMessageEvent = (messages, action, reason) => {
      if (this.onPendingMessageEvent) {
        this.onPendingMessageEvent(messageCollection, messages, action, reason);
      }
    };
    collectionHandler.onFailedMessageEvent = (messages, action, reason) => {
      if (this.onFailedMessageEvent) {
        this.onFailedMessageEvent(messageCollection, messages, action, reason);
      }
    };
    collectionHandler.onNewMessage = (messages, action, reason) => {
      if (this.onNewMessage) {
        this.onNewMessage(messageCollection, messages, action, reason);
      }
    };

    messageCollection.setCollectionHandler(collectionHandler);
  }

  static getMessageCollection() {
    return messageCollection;
  }
}

export default SendBirdSyncManagerEvent;
