/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.466 5c1.01 0 1.841.73 1.918 1.652l.006.14-.001.664 1.895-1.316c.056-.038.158-.102.24-.132a1.25 1.25 0 01.267-.054 1.087 1.087 0 01.917.378c.158.183.255.41.283.656l.009.15v5.722c0 .408-.184.776-.502.997a1.097 1.097 0 01-1.102.067l-.112-.067-1.895-1.317v.666c0 .94-.783 1.717-1.773 1.789l-.15.005H3.921c-1.008 0-1.838-.732-1.915-1.655L2 13.205V6.793c0-.94.782-1.715 1.771-1.787L3.921 5h8.546zm0 1.008H3.921c-.436 0-.783.293-.833.684l-.007.1v6.414c0 .407.314.732.733.78l.107.005h8.546c.435 0 .784-.294.835-.686l.007-.1V6.793c0-.44-.37-.784-.842-.784zm4.413.975l-2.454 1.705v2.623l2.455 1.703.017-.032a.308.308 0 00.016-.055l.006-.067V7.138c0-.05-.011-.097-.027-.133l-.013-.022z" _fill="#1D3861"/>'
  }
})
