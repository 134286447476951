/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-alert': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 1.6a6.4 6.4 0 110 12.8A6.4 6.4 0 018 1.6zm0 .8a5.6 5.6 0 100 11.2A5.6 5.6 0 008 2.4zm.007 7.867a.4.4 0 01.072.793l-.072.007a.4.4 0 01-.079-.794l.079-.006zM8 4.933a.4.4 0 01.394.329l.006.071V8a.4.4 0 01-.793.072L7.6 8V5.333c0-.22.18-.4.4-.4z" _fill="#E5092B"/>'
  }
})
