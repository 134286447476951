import configs from '@/configs';

export const IS_MOBILE_WIDTH = 768;

export const EMAILS = {
  help: configs.get('HELP_EMAIL'),
};

export const FEEDBACK_SCREEN_ACTIONS = {
  CLOSE_TAB: 'CLOSE_TAB',
  EMIT_EVENT: 'EMIT_EVENT',
};

export const AUTH_COOKIE = {
  token_name: 'atoken_sword',
  refresh_token_name: 'rtoken_sword',
  user_cookie_name: 'user_sword',
  token_domain: configs.get('TOKEN_COOKIE_DOMAIN'),
};

export const SESSION_TIMEOUT_CONFIG = {
  sessionLogoutTimeout: configs.get('AUTO_LOGOUT_TIMEOUT'),
  sessionTimerStartCookieName: 'session_timer_start',
};

export const AUTH_ERRORS = {
  MISSING_TOKEN: 1,
  MISSING_ENV: 2,
  MISSING_USER_INFO: 3,
  MISSING_INSTITUTIONS: 4,
  TOKEN_NEEDS_REFRESH: 5,
  LOGGED_OUT_ON_PORTAL: 6,
  USER_COOKIE_FORMAT: 7,
};

export const MESSAGE_STATUS = {
  PENDING: 'pending',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
};

/**
 * Auth Error codes mapping
 * @type {*}
 */
export const AUTH_ERROR_CODES = {
  10050: 'AUTH_REVOKED_TOKEN',
  10020: 'AUTH_INVALID_TOKEN',
  10030: 'AUTH_ACCESS_TOKEN_EXPIRED',
};

/**
 * HTTP Error status codes mapping
 * @type {*}
 */
export const HTTP_ERRORS_CODES = {
  401: 'UNAUTHORIZED',
  403: 'FORBIDDEN',
};

export const URLS = {
  BASE: configs.get('LOCALIZED_CORE_API_URL'),
  THERAPIST_API: configs.get('THERAPIST_API_URL'),
  MEMBER_API: configs.get('MEMBER_API_URL'),
};

export const SENDBIRD = {
  APP_ID: configs.get('APP_ID'),
  CHANNELS_CHUNK_SIZE: Number(configs.get('CHANNEL_QUERY_CHUNK_SIZE')),
  CHANNELS_ORDER_BY: 'latest_last_message',
  MAX_FILE_SIZE: 26214400, // in bytes
};

export const SENDBIRD_SYNC_MANAGER = {
  MESSAGE_COLLECTION_CAPACITY: 2000,
  MESSAGE_RESEND_POLICY: 'manual',
  AUTOMATIC_MESSAGE_RESEND_RETRY_COUNT: 0,
  MAX_FAILED_MESSAGE_COUNT_PER_CHANNEL: 50,
  FAILED_MESSAGE_RETENTION_DAYS: 7,
};

export const USER_ROLES = {
  PT: 'pt',
  MEMBER: 'member',
};

export const CHANNEL_TYPES = {
  PREVENTIVE: 'PREVENTIVE',
  VIRTUAL_PT: 'VIRTUAL_PT',
};

export function isAuthErrorType(expEv, resp) {
  const eCode = resp.data.error_code;
  const errorMatch = () => {
    if (expEv === '*') {
      // Any kind of auth error
      return !!AUTH_ERROR_CODES[eCode];
    }
    return AUTH_ERROR_CODES[eCode] === expEv || AUTH_ERROR_CODES[eCode] === `AUTH_${expEv}`;
  };

  return resp.status && resp.data.error_code && Object.prototype.hasOwnProperty.call(HTTP_ERRORS_CODES, resp.status) && errorMatch();
}

export const MESSAGES_TYPES = {
  ALL: 'all',
  UNREAD: 'unread',
  PENDING: 'pending',
};

export const MEMBER_MODALS = {
  SUMMARY: 'summary',
  NOTES: 'notes',
  SEND_DT_MESSAGE: 'sendDtMessage',
};
