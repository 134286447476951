import Vue from 'vue';
import VueRouter from 'vue-router';

import guards from './route-guards';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    components: { default: () => import('../views/MainWithSidebar.vue') },
    children: [
      {
        path: '/',
        name: 'Chat',
        component: () => import(/* webpackChunkName: "Chat" */ '../views/Chat.vue'),
        beforeEnter: guards.auth,
        children: [
          {
            path: 'channel/:channelId',
            name: 'Channel',
            component: () => import(/* webpackChunkName: "Chat" */ '@/components/chat/ChatWindow.vue'),
            props: true,
            beforeEnter: guards.auth,
          },
        ],
      },
    ],
  },
  {
    path: '/call',
    components: { default: () => import(/* webpackChunkName: "GroupCall" */ '../views/Main.vue') },
    children: [
      {
        path: '/',
        name: 'GroupCall',
        component: () => import(/* webpackChunkName: "GroupCall" */ '../views/GroupCall/GroupCall.vue'),
        children: [
          {
            path: ':roomId',
            name: 'GroupCallRoom',
            component: () => import(/* webpackChunkName: "GroupCall" */ '../views/GroupCall/GroupCallRoom.vue'),
            beforeEnter: guards.auth,
          },
          {
            path: 'public/:roomToken',
            name: 'PublicGroupCallRoom',
            component: () => import(/* webpackChunkName: "GroupCall" */ '../views/GroupCall/GroupCallRoom.vue'),
            beforeEnter: guards.tokenBasedAuth,
          },
        ],
      },
    ],
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "Feedbacks" */ '../views/Logout.vue'),
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "Feedbacks" */ '../views/FeedbackScreen.vue'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "Feedbacks" */ '../views/FeedbackScreen.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
