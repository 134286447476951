/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'telephone': {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.738 16.789a.55.55 0 00.468.26h4.412l.137-.006a1.432 1.432 0 001.295-1.425C17.05 7.516 10.483.95 2.382.95 1.592.95.95 1.59.95 2.382v4.412l.008.095a.55.55 0 00.338.416l4.411 1.764.085.027a.55.55 0 00.591-.254l1.055-1.758.177.1a9.156 9.156 0 013.2 3.2l.102.179-1.759 1.053-.072.052a.55.55 0 00-.156.624l1.765 4.412.043.085zm4.88-.84h-4.04l-1.452-3.63 1.804-1.083.076-.055a.55.55 0 00.134-.66A10.256 10.256 0 007.478 5.86l-.087-.034a.55.55 0 00-.628.245L5.682 7.875 2.05 6.422v-4.04l.007-.067a.332.332 0 01.325-.265c7.493 0 13.568 6.074 13.568 13.568a.332.332 0 01-.332.332z" _fill="#fff"/>'
  }
})
