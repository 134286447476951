/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy-link': {
    width: 12,
    height: 14,
    viewBox: '0 0 12 14',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.22.6a.416.416 0 00-.42.413c0 .227.188.412.42.412h7.076c.257 0 .466.205.466.458v8.705c0 .227.187.412.419.412.231 0 .419-.185.419-.412V1.883C11.6 1.174 11.016.6 10.296.6H3.22zM1.703 13.4C.984 13.4.4 12.826.4 12.117V4.283C.4 3.574.984 3 1.704 3h6.192c.72 0 1.304.574 1.304 1.283v7.834c0 .709-.584 1.283-1.304 1.283H1.704zm6.226-.8c.26 0 .47-.206.47-.46V4.26c0-.254-.21-.46-.47-.46H1.67c-.26 0-.47.206-.47.46v7.88c0 .254.21.46.47.46h6.26z" _fill="#A5B1C1"/>'
  }
})
