/**
 * Setting up validations package.
 * @module Validations Configs
 */

import Vue from 'vue';
import Vuelidate from '@sword-health/vuelidate/dist/vuelidate.min';
import vuelidateErrorExtractor from 'vuelidate-error-extractor';

import InputErrorWuk from '@sword-health/web-ui-kit/src/components/inputs/footnotes/InputError.vue';

const VALIDATIONS_COPIES_KEY = 'VALIDATIONS';

Vue.component('InputErrorWuk', InputErrorWuk);

Vue.use(Vuelidate);
Vue.use(vuelidateErrorExtractor, {
  /**
   * Optionally provide the template in the configuration.
   * or register like so Vue.component("FormField", templates.singleErrorExtractor.foundation6)
   */
  name: 'inputErrorMessage',
  template: InputErrorWuk, // you can also pass your own custom template
  i18n: VALIDATIONS_COPIES_KEY, // Path to validation messages. Can be deeply.nested.property.
});
