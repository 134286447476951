import configs from '@/configs';

const firebaseConfig = {
  apiKey: configs.get('FIREBASE_API_KEY'),
  authDomain: configs.get('FIREBASE_AUTH_DOMAIN'),
  databaseURL: configs.get('FIREBASE_DATABASE_URL'),
  projectId: configs.get('FIREBASE_PROJECT_ID'),
  storageBucket: configs.get('FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: configs.get('FIREBASE_MESSAGING_SENDER_ID'),
  appId: configs.get('FIREBASE_APP_ID'),
};

export default firebaseConfig;
