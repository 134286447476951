/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'copy': {
    width: 14,
    height: 16,
    viewBox: '0 0 14 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.524 0A.52.52 0 003 .516a.52.52 0 00.525.515h8.845c.322 0 .582.257.582.573v10.88a.52.52 0 00.524.516.52.52 0 00.524-.516V1.604C14 .718 13.27 0 12.37 0H3.524zM1.63 16C.73 16 0 15.282 0 14.396V4.604C0 3.718.73 3 1.63 3h7.74c.9 0 1.63.718 1.63 1.604v9.792C11 15.282 10.27 16 9.37 16H1.63zm7.782-1a.582.582 0 00.588-.576V4.576A.582.582 0 009.412 4H1.588A.582.582 0 001 4.576v9.848c0 .318.263.576.588.576h7.824z" _fill="#1D3861"/>'
  }
})
