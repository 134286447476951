/* eslint-disable */
require('./add')
require('./archive')
require('./audio')
require('./back-arrow')
require('./camera-off')
require('./camera')
require('./check')
require('./circle-alert')
require('./circle-check')
require('./clinical-records')
require('./close-cross')
require('./close')
require('./copy-link')
require('./copy')
require('./envelope-pending')
require('./envelope-sent')
require('./envelope')
require('./member-profile')
require('./microphone-off')
require('./microphone')
require('./other-file-types')
require('./prescriptions')
require('./results-charts')
require('./retry')
require('./search')
require('./send')
require('./settings')
require('./sound')
require('./telephone')
require('./text')
require('./user')
