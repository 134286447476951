/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'envelope-sent': {
    width: 16,
    height: 16,
    viewBox: '0 0 23 23',
    data: '<path pid="0" d="M1.948 0C.872 0 0 .817 0 1.825L0 13.775c0 .484.205.948.57 1.29.366.343.861.535 1.378.535h9.145A6.538 6.538 0 0111 14.4H1.815c-.34 0-.614-.256-.615-.572V1.771c0-.315.275-.571.615-.571h15.571c.34 0 .614.256.614.571V8.02c.412.031.813.101 1.2.206v-6.4C19.2.817 18.328 0 17.252 0H1.948z" _fill="#3BB638"/><path pid="1" d="M13.704 9.223l-1.6-1.53 3.96-3.578a.645.645 0 00.046-.902.625.625 0 00-.886-.048L9.686 8.174l-.992-.893-.003-.007a.855.855 0 00-.093-.081L4.128 3.16a.622.622 0 00-.886.052.642.642 0 00.05.902L7.3 7.723 3.307 11.49a.637.637 0 00.43 1.104.625.625 0 00.427-.168l4.081-3.847 1.027.924a.624.624 0 00.837-.005l1.056-.953 1.586 1.518c.29-.31.608-.59.953-.839zM22.458 14.388a.6.6 0 00-.916-.776l-4.798 5.67a.4.4 0 01-.588.025l-2.732-2.731a.6.6 0 00-.848.848l2.731 2.732a1.6 1.6 0 002.353-.098l4.798-5.67z" _fill="#3BB638"/>'
  }
})
