/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M32 6.4C17.845 6.4 6.4 17.845 6.4 32c0 14.155 11.445 25.6 25.6 25.6 14.156 0 25.6-11.445 25.6-25.6C57.6 17.845 46.156 6.4 32 6.4zm0 3.2c12.313 0 22.4 10.273 22.4 22.812 0 4.532-1.335 8.762-3.56 12.388-4.154-4.08-10.829-6.496-18.84-6.496-8.01 0-14.686 2.266-18.84 6.496-2.225-3.626-3.56-7.856-3.56-12.388C9.6 19.872 19.688 9.6 32 9.6zm0 44.8c-7.04 0-13.314-2.86-17.6-7.353C18.073 43.643 24.348 41.6 32 41.6c7.653 0 13.927 1.906 17.6 5.447C45.315 51.54 39.04 54.4 32 54.4zm0-19.2c5.302 0 9.6-4.218 9.6-9.6S37.302 16 32 16c-5.301 0-9.6 4.218-9.6 9.6s4.156 9.6 9.6 9.6zm0-16c3.467 0 6.4 2.86 6.4 6.4 0 3.54-2.933 6.4-6.4 6.4-3.466 0-6.4-2.86-6.4-6.4 0-3.54 2.8-6.4 6.4-6.4z" _fill="#1D3861"/>'
  }
})
