/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'microphone-off': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.948 2.091c.24.241.24.63 0 .871l-5.306 5.314v.644c0 1.482-1.096 2.694-2.481 2.782l-.161.005c-.238 0-.469-.033-.688-.095L7.777 13.15A4.152 4.152 0 0010 13.8c2.48 0 4.5-2.224 4.5-4.979 0-.291.224-.527.5-.527s.5.236.5.527c0 3.143-2.192 5.731-5 6.009v2.116h2.357c.276 0 .5.236.5.528a.52.52 0 01-.419.52l-.08.007H7.142a.514.514 0 01-.5-.527.52.52 0 01.419-.52l.08-.008H9.5v-2.116a5.155 5.155 0 01-2.471-.93l-4.11 4.117a.615.615 0 01-.785.071l-.086-.071a.616.616 0 010-.87l4.058-4.065c-.993-1.092-1.606-2.6-1.606-4.26 0-.292.224-.528.5-.528s.5.236.5.527c0 1.384.51 2.633 1.33 3.535l1.382-1.383a2.85 2.85 0 01-.855-2.052V4.787C7.357 3.247 8.54 2 10 2c1.46 0 2.643 1.248 2.643 2.787l-.001 1.747 4.436-4.442c.24-.24.63-.24.87 0zm-6.35 7.233l-1.299 1.3c.64-.124 1.15-.639 1.299-1.3zM10 3.054c-.907 0-1.643.776-1.643 1.733V8.92c0 .53.226 1.005.582 1.323l2.703-2.707V4.787c0-.906-.66-1.65-1.5-1.726L10 3.055z" _fill="#1D3861"/>'
  }
})
