/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'camera-off': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.948 2.091c.24.241.24.63 0 .871l-3.576 3.584c.005.035.009.07.012.106l.006.14-.001.664 1.895-1.316c.056-.038.158-.102.24-.132a1.25 1.25 0 01.267-.054 1.087 1.087 0 01.917.378c.158.183.255.41.283.656l.009.15v5.722c0 .408-.184.776-.502.997a1.097 1.097 0 01-1.102.067l-.112-.067-1.895-1.316v.665c0 .94-.784 1.717-1.773 1.789l-.15.005H5.928l-3.01 3.016a.615.615 0 01-.784.071l-.086-.071a.616.616 0 010-.87L4.189 15h-.268c-1.008 0-1.838-.732-1.915-1.655L2 13.205V6.793c0-.94.782-1.715 1.771-1.787L3.921 5h8.546c.483 0 .926.168 1.265.444l3.346-3.352c.24-.24.63-.24.87 0zm-5.482 3.917H3.921c-.436 0-.783.293-.833.684l-.007.1v6.414c0 .407.314.732.733.78l.107.005h1.275l7.802-7.812a.884.884 0 00-.531-.17zm.842 1.6l-6.372 6.383h5.53c.436 0 .785-.294.836-.686l.007-.1-.001-5.596zm3.57-.625l-2.453 1.705v2.623l2.455 1.703.017-.032a.316.316 0 00.016-.055l.006-.067V7.138c0-.05-.011-.097-.027-.133l-.013-.022z" _fill="#1D3861"/>'
  }
})
