/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'other-file-types': {
    width: 31,
    height: 36,
    viewBox: '0 0 31 36',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M29.856 7.914L22.774.958a1.016 1.016 0 00-.712-.291H4.157c-2.09 0-3.79 1.7-3.79 3.79v27.087c0 2.09 1.7 3.79 3.79 3.79H26.37c2.09 0 3.79-1.7 3.79-3.79V8.638c0-.272-.11-.533-.305-.724zm-6.778-3.81l3.583 3.519h-3.26a.323.323 0 01-.323-.323V4.103zM4.157 33.301H26.37a1.76 1.76 0 001.758-1.758V9.654h-4.728A2.357 2.357 0 0121.047 7.3V2.698H4.157a1.76 1.76 0 00-1.759 1.758v27.088c0 .97.79 1.758 1.759 1.758z" _fill="#1D3861"/>'
  }
})
