import Vue from 'vue';

import SendbirdCallAction from '@/scripts/sendbird-integration/SendbirdCallAction';

import groupCall from './GroupCallStore';
import media from './MediaStore';

const sbcAction = new SendbirdCallAction();

export default {
  namespaced: true,
  modules: {
    group: groupCall,
    media,
  },
  state: {
    nickname: null,
    plainProfileUrl: null,
    userId: null,
    videoUrl: null,
    friendName: null,
  },
  getters: {
    getSendbirdId: state => state.userId,
  },
  mutations: {
    setSendbirdCallUser(state, payload) {
      Object.keys(payload).forEach(key => {
        if (key in state) {
          Vue.set(state, key, payload[key]);
        }
      });
    },
  },
  actions: {
    async initSendbirdCall() {
      console.log('[sendbird-call] initializing sendbird call');
      sbcAction.init();
    },
    async connectToSendbirdCall({ rootGetters, getters, commit }) {
      const userId = getters.getSendbirdId || rootGetters['chat/getSendbirdId'];
      const accessToken = rootGetters['user/auth/sendbirdToken'];

      try {
        console.log(`[sendbird-call] connecting to sendbird call with userId ${userId}`);
        const user = await sbcAction.connect(userId, accessToken);
        commit('setSendbirdCallUser', user);
        return user;
      } catch (error) {
        console.log('[sendbird-call] Error connecting to sendbird call', error);
        throw error;
      }
    },
    disconnectFromSendbirdCall() {
      console.log('[sendbird-call] disconnecting from sendbird call');
      return sbcAction.disconnect();
    },
  },
};
