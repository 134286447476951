/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 16,
    height: 16,
    viewBox: '0 0 32 31',
    data: '<path pid="0" d="M31.7 28.7l-8-8c1.8-2.2 2.9-5 2.9-8 0-7-5.8-12.7-12.8-12.7C6.7 0 1 5.7 1 12.7c0 7 5.8 12.7 12.8 12.7 3.3 0 6.3-1.2 8.5-3.2l8 8c.4.4 1 .4 1.4 0 .4-.5.4-1.1 0-1.5zm-17.9-5.4C7.8 23.3 3 18.5 3 12.7 3 6.8 7.8 2 13.8 2c6 0 10.8 4.8 10.8 10.7 0 5.8-4.8 10.6-10.8 10.6z"/>'
  }
})
