import Vue from 'vue';
import axios from 'axios';
import { URLS } from '@/configs/constants';
import '@/scripts/interceptors/request-interceptors';
import '@/scripts/interceptors/response-interceptors';

axios.defaults.baseURL = URLS.BASE;

const GLOBAL_ALIAS = '$http';

Vue[GLOBAL_ALIAS] = axios;
Object.defineProperties(Vue.prototype, {
  [GLOBAL_ALIAS]: {
    get() {
      return axios;
    },
  },
});

export default axios;
