/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close-cross': {
    width: 16,
    height: 16,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M.7 2.9zm30.4 26.7L17.3 16.2 30.7 2.4c.3-.4.3-1-.1-1.4-.4-.4-1-.4-1.4 0L15.9 14.8 2.1 1.4c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l13.8 13.4L1.1 30c-.4.4-.4 1 0 1.4.4.4 1 .4 1.4 0l13.4-13.8L29.7 31c.4.4 1 .3 1.4 0 .4-.4.4-1 0-1.4z"/>'
  }
})
