/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'audio': {
    width: 32,
    height: 30,
    viewBox: '0 0 32 30',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.723 8.009a1.063 1.063 0 011.488-.217 9.579 9.579 0 013.857 7.676 9.579 9.579 0 01-3.857 7.676 1.064 1.064 0 01-1.272-1.704 7.452 7.452 0 003.002-5.972 7.452 7.452 0 00-3.002-5.971 1.064 1.064 0 01-.216-1.488z" _fill="#1D3861"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M24.376 2.86a1.063 1.063 0 011.495-.162A16.387 16.387 0 0132 15.468c0 4.961-2.254 9.654-6.13 12.77a1.063 1.063 0 01-1.332-1.657 14.26 14.26 0 005.335-11.113 14.26 14.26 0 00-5.335-11.113 1.063 1.063 0 01-.162-1.495zM11.536.713c1.522-1.523 4.126-.444 4.126 1.709v24.401c0 2.154-2.604 3.232-4.126 1.71l-5.994-5.995a.29.29 0 00-.205-.085h-2.92A2.417 2.417 0 010 20.036V9.21a2.417 2.417 0 012.417-2.417h2.92a.29.29 0 00.205-.085L11.536.713zm1.999 1.709a.29.29 0 00-.495-.205L7.046 8.21a2.417 2.417 0 01-1.71.708h-2.92a.29.29 0 00-.29.29v10.827c0 .16.13.29.29.29h2.92c.642 0 1.256.255 1.71.708l5.994 5.994a.29.29 0 00.495-.205V2.422z" _fill="#1D3861"/>'
  }
})
