/**
 * Find the message index considering the messages array
 * Message type = SendBird.UserMessage | SendBird.FileMessage | SendBird.AdminMessage
 * @param {Message}         message
 * @param {Array<Message>}  messages
 */
export const findMessageIndex = (message, messages) => messages.findIndex(currentMessage => {
    const messageId = message.messageId !== 0 && currentMessage.messageId !== 0;
    const isSameMessageId = messageId && currentMessage.messageId === message.messageId;
    const isSameReqId = !messageId && currentMessage.reqId === message.reqId;

    return isSameMessageId || isSameReqId;
  });

/**
 * Find the right position for newMessage considering the messages array
 * Message type = SendBird.UserMessage | SendBird.FileMessage | SendBird.AdminMessage
 * @param {Message}         newMessage
 * @param {Array<Message>}  messages
 * @param {Boolean}         isRequestId
 */
export const findMessagePosition = (newMessage, messages, isRequestId = false) => {
  const index = messages.findIndex(message => {
    const isSameMessageId = !isRequestId && newMessage.messageId !== 0 && message.messageId !== 0 && message.messageId === newMessage.messageId;
    const isSameReqId = isRequestId && message.reqId === newMessage.reqId;
    const isLaterDate = message.createdAt >= newMessage.createdAt;

    return isSameMessageId || isSameReqId || isLaterDate;
  });
  return index >= 0 ? index : messages.length;
};

/**
 * Merge failed sent messages with successfully sent messages and order them correctly
 * Message type = SendBird.UserMessage | SendBird.FileMessage | SendBird.AdminMessage
 * @param {Array<Message>} failedMessages
 * @param {Array<Message>} successfulMessages
 */
export const mergeFailedWithSuccessful = (failedMessages, successfulMessages) => {
  const wholeMessages = [ ...successfulMessages ];
  failedMessages.forEach(message => {
    const isFailed = message.messageId === 0 && message.requestState === 'failed';
    const index = findMessagePosition(message, wholeMessages, isFailed);

    if (index >= 0) wholeMessages.splice(index, 0, message);
  });
  return wholeMessages;
};
