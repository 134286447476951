/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 16,
    height: 16,
    viewBox: '0 0 22 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.15 5.372L.939.162.874.105.79.056A.553.553 0 00.16.16L.097.237A.55.55 0 00.161.94L5.372 6.15l-5.21 5.211-.056.065a.553.553 0 00.055.713l.076.064a.55.55 0 00.702-.064L6.15 6.927l5.211 5.212.065.055a.553.553 0 00.713-.055l.064-.076a.55.55 0 00-.064-.702L6.927 6.15 12.139.939l.055-.065a.55.55 0 00-.833-.713L6.15 5.372z" _fill="#fff"/>'
  }
})
